import React from 'react';
import PropTypes from 'prop-types';
import styles from './Profiles.module.scss';
import fetch from 'node-fetch';
import moment from "moment";
import $ from 'jquery';
import _ from 'lodash';


import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';
import empty_profile from '../../assets/images/empty_profile.jpg';
import tutorialEventBus from "../../tutorialEventBus";

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';
import {Checkbox, RadioGroup} from "@material-ui/core";

import { FaExclamationTriangle, FaLock, FaBell, FaPlus } from "react-icons/fa";


import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

import ReactTooltip from 'react-tooltip';

import { Profile } from "./Profile";


class Profiles extends React.Component {

    state = {
    	profiles: [],
        profile_group: {},
        main_que: [{
            count: {},
            active: [],
            waiting: [],
        }],
        tasks: {},
    	selected: null,
        "new-profile": null,
        "group": null,

        filter: {
            temp_block: false,
            opening: false,
            backup: false,
            invalid_password: false,
        }

    }

    refresh() {

    }

    update_data(props, type_update) {
    	var token = localStorage.getItem('user_token');
    	var account_id = props.user ? props.user._id : null;

    	if (!account_id) {

        	this.setState({
        		profiles: [],
        	});

    		return;
    	}

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/profiles?access_token=${token}&account=${account_id}`, {})
        .then(response => response.json())
        .then(data => {
        	this.setState({
        		profiles: data,
                active_count: data.filter((p) => {
                    return !p.locked_account && !p.temporary_block && p.login_try_count == 0;
                }).length,
                temp_block_count: data.filter((p) => {
                    return !p.locked_account && p.temporary_block;
                }).length,
                opening_count: data.filter((p) => {
                    return p.locked_account;
                }).length,
                backup_count: data.filter((p) => {
                    return !p.locked_account && p.hidden;
                }).length,
                invalid_password_count: data.filter((p) => {
                    return !p.locked_account && p.login_try_count > 0;
                }).length,
        	});
            if (type_update !== 'interval_update') {
                setTimeout(() => {
                    if ($('#users .active')) {
                        if ($('#users .active')[0]) {

                            var scroll_left_amount = document.getElementById("users").scrollLeft;
                            var current_position_x = $('#users .active')[0].getBoundingClientRect().x;
                            var container_width = document.getElementById("users").getBoundingClientRect().width;
                            var element_width = $('#users .active')[0].getBoundingClientRect().width;
                            var amount_to_move = current_position_x - ((container_width+element_width)/2) + scroll_left_amount;
                            document.getElementById("users").scroll(amount_to_move, 0)


                            // document.getElementById("users").scroll($('#users .active')[0].getBoundingClientRect().x+document.getElementById("users").scrollLeft-240, 0)
                        }
                    }
                }, 0)
            }
            
        })
        .catch(err => {

        });;


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-profile-group?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                profile_group: data,
            });
        })
        .catch(err => {

        });
    }
    update_data_live(props) {
        var token = localStorage.getItem('user_token');

        var query = queryString.parse(this.props.location.search)


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/profile-tasks?access_token=${token}&selected=${query.profile ? query.profile : ''}`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                tasks: data,
            });
        })
        .catch(err => {

        });

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/main-que?access_token=${token}&selected=${query.profile ? query.profile : ''}`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                main_que: [data],
            });

            tutorialEventBus.dispatch("live_events", data.live_tasks);

        })
        .catch(err => {

        });
    }

    componentDidUpdate(props) {
        ReactTooltip.rebuild();
    }

    componentWillReceiveProps(props) {
        var query = queryString.parse(this.props.location.search);
    	this.setState({
    		selected: query.profile,
            group: query.group,
    	});


    	if (props.user !== this.props.user) {
    		this.update_data(props);
    	}

        if (query['new-profile'] && this.state.latest_new !== query['new-profile']) {
            this.setState({
                latest_new: query['new-profile'],
            });

            this.update_data(props);
        }
    }
    
    componentWillMount(props) {
        var query = queryString.parse(this.props.location.search);
    	this.setState({
    		selected: query.profile,
            group: query.group,
            debug: query.debug,
    	});
	
		this.update_data(this.props);


        var intervalId = setInterval(() => {
            this.update_data_live(this.props);    
            this.update_data(this.props, 'interval_update');    
        }, 60000);

        this.setState({ intervalId: intervalId })
        this.update_data_live(this.props);

    }

    componentWillUnmount(){
        clearInterval(this.state.intervalId)
    }
    selectProfile(profile){
        var profile_in_selected_group = _(this.state.profile_group).at(`${profile._id}.${this.state.group}`).value();

        this.props.tutorialEvent({action: 'select_profile'});

        if (profile_in_selected_group) {
            // window.alert(profile_in_selected_group);
            this.props.tutorialEvent({action: 'select_profile_in_group'});
        }

        this.props.updateSearch({profile: profile._id});
    }


    render() {
    	const { t, i18n } = this.props;
        return (
            <React.Fragment>
                {this.state.debug && <div className="profiles-container"><section id="users" className="py-2">

                      <div className="row">
                        {this.state.main_que && this.state.main_que[0] && this.state.main_que[0].actions && Object.keys(this.state.main_que[0].actions).map((action_name) => <div className="col-xl-2 col-md-6 mb-4">
                          <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                              <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                    {action_name}</div>

                                    {Object.keys(this.state.main_que[0].actions[action_name]).map((name) => <div><strong>{name}:</strong> {this.state.main_que[0].actions[action_name][name]}</div>)}
                                </div>
                                <div className="col-auto">
                                  <i className="fas fa-calendar fa-2x text-gray-300" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>)}
                      </div>


                    <h2>Live</h2>
                    {this.state.main_que && this.state.main_que[0] && this.state.main_que[0].active && this.state.main_que[0].active.map((task,index) => (<React.Fragment>
                        {/*<div>{index} {task.data.user} {task.data.data.user}</div>*/}
                        {[this.state.profiles.find((u) => {
                            if (task) {
                                return u._id === ((task.data.user) || (task.data.data && task.data.data.user));
                            }
                        }) || {}].map((profile) => <React.Fragment>
                            <div>
                                <img onClick={this.props.updateSearch.bind(this, {profile: profile._id})} data-tip={profile.first_name + " " + profile.last_name} width="60px" className="profile rounded rounded-circle border border-3" src={"/api/users/profile/" + profile._id} g="img/user1.png" alt=""/>
                                {task.data.type} {task.data && task.data.data._id} <strong>{moment(task.timestamp).fromNow()}</strong> <button className="btn btn-danger">Remove From Que</button>
                            </div>
                        </React.Fragment>)}

                    </React.Fragment>))}
                    <h2>Waiting</h2>
                    {this.state.main_que[0].waiting && this.state.main_que[0].waiting.map((task) => (<React.Fragment>
                        <div>
                            <img height="50px" width="60px" className="profile rounded rounded-circle border border-3" src={"/api/users/profile/" + (task.data.user || task.data.data && task.data.data.user)} g="img/user1.png" alt=""/>
                            {task.data.type} {task.data.data && task.data.data._id}
                        </div>
                    </React.Fragment>))}



                    <h2>Live Selected User</h2>
                    {this.state.main_que[0].selected_q && this.state.main_que[0].selected_q.active.map((task,index) => (<React.Fragment>
                        {/*<div>{index} {task.data.user} {task.data.data.user}</div>*/}
                        {[this.state.profiles.find((u) => {
                            if (task) {
                                return u._id === ((task.data.user) || (task.data.data && task.data.data.user));
                            }
                        }) || {}].map((profile) => <React.Fragment>
                            <div>
                                <img onClick={this.props.updateSearch.bind(this, {profile: profile._id})} data-tip={profile.first_name + " " + profile.last_name} width="60px" className="profile rounded rounded-circle border border-3" src={"/api/users/profile/" + profile._id} g="img/user1.png" alt=""/>
                                {task.data.type}_{task.id}_{(task.data.user || task.data.data && task.data.data.user)} {task.data && task.data.data._id} <strong>{moment(task.timestamp).fromNow()}</strong> <button className="btn btn-danger">Remove From Que</button>
                            </div>
                        </React.Fragment>)}

                    </React.Fragment>))}
                    <h2>Waiting Selected User</h2>
                    {this.state.main_que[0].selected_q && this.state.main_que[0].selected_q.waiting.map((task) => (<React.Fragment>
                        <div>
                            <img height="50px" width="60px" className="profile rounded rounded-circle border border-3" src={"/api/users/profile/" + (task.data.user || task.data.data && task.data.data.user)} g="img/user1.png" alt=""/>
                            {task.data.type}_{task.id}_{(task.data.user || task.data.data && task.data.data.user)} {task.data.data && task.data.data._id}
                        </div>
                    </React.Fragment>))}

                    {this.state.debug && this.state.profiles.filter((p) => {
                        if (!this.state.filter.temp_block) {

                        }
                        if (!this.state.filter.opening) {
                            
                        }
                        if (!this.state.filter.backup) {
                            
                        }
                        if (!this.state.filter.invalid_password) {
                            
                        }
                    }).map((profile) => (<React.Fragment>
                        {(queryString.parse(this.props.location.search).profile === profile._id) && <React.Fragment>
                            <h2>{profile.first_name} {profile.last_name}</h2>
                            {this.state.tasks[profile._id] && <span>
                                <h4>active</h4>
                                {this.state.tasks[profile._id].active.map((w) => <div>
                                    {w.data.type} - {w.data.data ? w.data.data._id : ''}
                                </div>)}
                                <h4>waiting</h4>
                                {this.state.tasks[profile._id].waiting.map((w) => <div>
                                    {w ? w.data.type : ''} - {w && w.data.data ? w.data.data._id : ''}
                                </div>)}
                            </span>}
                        </React.Fragment>}

                    </React.Fragment>))}
                </section></div>}


              {/*<div style={{textAlign: 'center'}}>
                  <a className="btn btn-warning" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','profile', 'add']})}>
                    {t('Add Profile')}
                  </a>
              </div>*/}

                  <div className="users-section">
                <section id="users" className="py-2 users">

                  {this.state.profiles.map((profile) => (<React.Fragment>

                    <Profile
                        t={t}
                        name={"profile"}
                        type={"profile"}
                        debug={this.state.debug}
                        key={"ggggggggg"}
                        props={this.props}
                        state={this.state}
                        selectProfile={this.selectProfile.bind(this)}
                        profile={profile}
                        tasks={this.state.tasks[profile._id]}
                        updateSearch={this.props.updateSearch}
                    />

                   </React.Fragment>))}
                </section>
                {/*<section className="py-2 text-center profile-stats">
                    <div className="prfiles-label">{t('Active')} ({this.state.profiles.length} / {this.state.active_count})</div>
                    {this.state.temp_block_count ? <div className="prfiles-label">
                        <Checkbox 
                          onClick={(e) => {
                            this.state.filter.temp_block = !this.state.filter.temp_block;
                            this.setState({
                                filter: this.state.filter,
                            })
                          }}
                          style={{padding: '0'}}
                          checked={this.state.filter.temp_block}
                        />

                        {t('Temp Block')} ({this.state.temp_block_count})
                    </div> : null}
                    {this.state.opening_count ? <div className="prfiles-label">
                        <Checkbox 
                          onClick={(e) => {
                            this.state.filter.opening = !this.state.filter.opening;
                            this.setState({
                                filter: this.state.filter,
                            })
                          }}
                          style={{padding: '0'}}
                          checked={this.state.filter.opening}
                        />

                        {t('Opening')} ({this.state.opening_count})
                   </div> : null}
                    {this.state.backup_count ? <div className="prfiles-label">
                        <Checkbox 
                          onClick={(e) => {
                            this.state.filter.backup = !this.state.filter.backup;
                            this.setState({
                                filter: this.state.filter,
                            })
                          }}
                          style={{padding: '0'}}
                          checked={this.state.filter.backup}
                        />

                        {t('Backup')} ({this.state.backup_count})
                    </div> : null}
                    {this.state.invalid_password_count ? <div className="prfiles-label">
                        <Checkbox 
                          onClick={(e) => {
                            this.state.filter.invalid_password = !this.state.filter.invalid_password;
                            this.setState({
                                filter: this.state.filter,
                            })
                          }}
                          style={{padding: '0'}}
                          checked={this.state.filter.invalid_password}
                        />

                        {t('Wrong Password')} ({this.state.invalid_password_count})
                    </div> : null}
                </section>*/}

                <section className="py-2 add-new-user text-center">
                    <div className="button-click" style={{margin: 'auto'}} onClick={this.props.tutorialEvent.bind(this, {action: 'add_new_user_button'}) }>
                        <span className="position-relative">







                        {/*<span style={{width: '60px'}} data-tip={t('Add Profile')} onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','profile', 'add']})}>
                            <span className="add-new-user-button">
                                <img width="60px" className={`profile rounded rounded-circle border border-3`} src={empty_profile} g="img/user1.png" alt="" />
                            </span>
                            {<FaPlus style={{
                                color: 'green',
                                fontSize: '28px',
                                lineHeight: '41px',
                                opacity: 0.6,
                                position: 'absolute',
                                top: -3,
                                left: 15,
                            }}/>}
                            <div className={'text-center'} style={{display: 'flex'}}>
                                <span style={{color: '#000', fontSize: '15px', lineHeight: '17px', fontWeight: "bold"}}>{t('Add Profile')}</span>
                            </div>
                        </span>*/}

                        <button onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','profile', 'add']})} className="btn btn-primary w-100" >{t('Add Profile')}</button>



                        </span>
                    </div>
                </section>
                </div>
            </React.Fragment>
        );
    }
}



Profiles.propTypes = {};

Profiles.defaultProps = {};

export default Profiles;
