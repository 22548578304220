import React from 'react';
import PropTypes from 'prop-types';
import styles from './SchedulePostPopup.module.scss';
import fetch from 'node-fetch';
import _ from 'lodash';
import Multiselect from 'multiselect-react-dropdown';


import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import moment from 'moment';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';
import {
  BrowserRouter as Router,
  Switch as SwitchRouter,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';


import DateFnsUtils from '@date-io/date-fns';
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";



import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

import { FaClock, FaPeopleGroup,FaUserTie,FaRegQuestionCircle,FaRegIdCard, FaImage,FaCog, FaImages,FaUsers, FaUserPlus,FaRegCalendarAlt, aUsers, FaChartBar, FaAd, FaRegNewspaper, FaVideo, FaExternalLinkSquareAlt, FaEquals, FaCube, FaDollarSign } from "react-icons/fa";

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);



// select_campaign
// Select Posts
// Schedule
// Groups
// Custom Groups
// Profiles
// Settings
// Summary

class SchedulePostPopup extends React.Component {

    state = {
      schedule_for_me: {
        amount: 1,
        selected_list: [{name: `${queryString.parse(this.props.location.search).slot}:00`, id: parseInt(queryString.parse(this.props.location.search).slot)}],
      },
      steps: {
        new: [
          "select_type",
          "select_campaign",
          "select_posts",
          "profiles",
          "groups",
          "custom_groups",
          "settings",
          "summary",
        ],
        existing: [
          "select_type",
          "select_post_url",
          "select_comment_library",
          "profiles",
          "settings",
          "summary",
        ],
      },
      "filter_query": {
        by_selected: {
          value: 'all',
          query: {},
        },
      },      
    	// current_step: 'select_campaign',
      current_step: 'select_type',
      complete_steps: {
        "select_type": () => {
          return true;
        },
        "select_post_url": () => {
          return this.get_post_id_from_url(this.state.post.post_url);
        },
        "select_comment_library": () => {
          return true;
        },
        "select_campaign": () => {
          // return true;
          return this.state.post.campaign._id;
        },
        "select_posts": () => {
          return this.state.post.settings.all_posts || this.state.post.selected_posts.length > 0;
        },
        "profiles": () => {
          return this.state.post.settings.comment_users.length > 0;
        },
        "groups": () => {
          return this.state.post.selected_groups.length > 0;
        },
        "custom_groups": () => {
          return this.calulateAllPosts();
        },
        "settings": () => {
          return true;
        },
        "summary": () => {
          return false;
        },
      },
      post: {
      },
  	  selected: null,
      search_text: '',
      profiles: [],
      campaigns: null,
      posts: [],
      custom_group_settings: {}, 
      groups: null,
      edit_campaign_posts: [],

      selected_campaign: null,
      selected_edit_campaign: null,

      times: [
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
      ]
    }

    refresh() {

    }

    selectAll(groups) {
      this.state.post.selected_groups = [...new Set(this.state.post.selected_groups.concat(groups.map((a) => a._id)))];
      this.setState({
        post: this.state.post
      });
    }

    next() {
      setTimeout(() => {
        this.setState({
          current_step: this.state.steps[this.state.post.post_type] [this.state.steps[this.state.post.post_type].indexOf(this.state.current_step) + 1],
          filter_query: {
            by_selected: {
              value: 'all',
              query: {},
            },
          },
        })
      }, 0)
    }

    delete() {
      var token = localStorage.getItem('user_token');
        if (this.state.selected) {
          fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/delete-multi-campaign-slot?access_token=${token}&post=${this.state.selected}`, {})
          .then(response => response.json())
          .then(data => {
            NotificationManager.success('deleted successfully');
            this.props.updateSearch({'schedule-new-post': undefined, "new-post": Math.random()})

          })
          .catch(err => {
            NotificationManager.error('Error');
          });
        }
    }

    get_post_id_from_url(url) {

      const regex = /(?:(?:http|https):\/\/(?:www|m|mbasic|business)\.(?:facebook|fb)\.com\/)(?:photo(?:\.php|s)|permalink\.php|video\.php|media|watch\/|questions|groups\/([0-9]+|[^\/]+\/[\d]+)|notes|[^\/]+\/(?:activity|posts|videos|photos))[\/?](?:fbid=|multi_permalinks=|story_fbid=|id=|b=|v=|)(([0-9]+|[^\/]+\/[\d]+))/gm;
      const regex_long = /(?:(?:http|https):\/\/(?:www|m|mbasic|business)\.(?:facebook|fb)\.com\/)(?:photo(?:\.php|s)|permalink\.php|video\.php|media|watch\/|questions|groups\/([0-9]+|[^\/]+\/[\d]+)|notes|[^\/]+\/(?:activity|posts|videos|photos))[\/?](?:fbid=|multi_permalinks=|story_fbid=|id=|b=|v=|)(([A-Za-z0-9]+|[^\/]+\/[A-Za-z0-9]+))/gm;

      // Alternative syntax using RegExp constructor
      // const regex = new RegExp('(?:(?:http|https):\\/\\/(?:www|m|mbasic|business)\\.(?:facebook|fb)\\.com\\/)(?:photo(?:\\.php|s)|permalink\\.php|video\\.php|media|watch\\/|questions|groups\\/([0-9]+|[^\\/]+\\/[\\d]+)|notes|[^\\/]+\\/(?:activity|posts|videos|photos))[\\/?](?:fbid=|multi_permalinks=|story_fbid=|id=|b=|v=|)(([0-9]+|[^\\/]+\\/[\\d]+))', 'gm')

      var r = regex.exec(url || '');
      var rr = regex_long.exec(url || '');

      if (r) {
        return this.state.post.post_url_id = r[3].replace(/\D/g, '');
      } else if (rr) {
        // console.log(rr, 777)
        return this.state.post.post_url_id = rr[3];
      }

      return '';

      // while ((m = regex.exec(str)) !== null) {
      //     // This is necessary to avoid infinite loops with zero-width matches
      //     if (m.index === regex.lastIndex) {
      //         regex.lastIndex++;
      //     }
          
      //     // The result can be accessed through the `m`-variable.
      //     m.forEach((match, groupIndex) => {
      //         console.log(`Found match, group ${groupIndex}: ${match}`);
      //     });
      // }


    }

    save() {
      var query = queryString.parse(this.props.location.search);

        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/save-schedule-new-post?access_token=${token}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                post: this.state.post,
                interval: query.interval,
                day: query.day,
              }),
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('saved scheduled campaign successfully');
          this.props.updateSearch({'schedule-new-post': undefined, "new-post": Math.random()})
        })
        .catch(err => {
            NotificationManager.error('Error adding campaign');
        })
    }
    get_posts(state_name, campaign_id) {
        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/posts?access_token=${token}&campaign=${campaign_id}`, {})
        .then(response => response.json())
        .then(data => {
          var q = {};
          q[state_name] = data;
          this.setState(q);
        })
        .catch(err => {

        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.post && this.state.post.settings.edit_campaign && this.state.post.settings.edit_campaign !== this.state.selected_edit_campaign) {
            this.setState({
                selected_edit_campaign: this.state.post.settings.edit_campaign,
            })
            setTimeout(() => {
                this.get_posts('edit_campaign_posts', this.state.post.settings.edit_campaign);
            }, 1);
        }

        if (this.state.post && this.state.post.campaign._id && this.state.post.campaign._id !== this.state.selected_campaign) {
            this.setState({
                selected_campaign: this.state.post.campaign._id,
            })
            setTimeout(() => {
                this.get_posts('posts', this.state.post.campaign._id);
            }, 1);
        }

        ReactTooltip.rebuild();
    }

    update_data(props) {
    	var token = localStorage.getItem('user_token');



        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/profiles?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                profiles: data,
            });
        })
        .catch(err => {

        });;

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/campaigns?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                campaigns: data,
            });
        })
        .catch(err => {

        });


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/groups?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            groups: data,
          });
            this._sort();
        })
        .catch(err => {

        });

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/comment-groups?all=true&access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            comment_groups: data,
          });
        })
        .catch(err => {

        });

    }
    getDisplayComments(sort_by, search_text, amount_to_display, filter_by_profile, filter_query, selected) {
      search_text = '';
      this.state.comment_groups.map((g) => {
        g.follow_count = g.follow_count | 0;
      })

      var groups = _.orderBy(this.state.comment_groups, [sort_by, 'follow_count', 'today_slot_count', 'users_in_group_count'],['desc'])

      return groups.filter((group) => {
          return group.name.toLowerCase().indexOf(search_text.toLowerCase()) > -1;
        }).filter((group) => {
          if (this.state.selected_campaign) {
            return group.campaigns.indexOf(this.state.selected_campaign) > -1;
          }
          return true;
        })
        .slice(0,amount_to_display)

    }

    componentWillReceiveProps(props) {
      var id = this.props.location.pathname.split('/').pop()

    	if (id !== this.state.selected) {
  			setTimeout(() => {
  				this.update_data(props);	
  			}, 0)
    	}
    }

    componentWillMount(props) {
      var query = queryString.parse(this.props.location.search);

    	this.setState({
        post: {
          settings: {
            "max_comments": 50,
            "max_daily_posts": 1000,
            "max_immediate_comment_count": 10,
            "comment_duration_hours": 6,
            "immediate_pump": false,

            reply_users: [],
            comment_users: [],
          },
          times: [
            {
              time: parseInt(query.slot),
              post_amount: 1,
              group_map: {},
            },
            // {
            //   time: 12,
            //   group_map: {},
            // },
          ],
          campaign: {},

          selected_groups: [],
          selected_posts: [],
        },
    		selected: query['schedule-new-post'] || '',
        allow_edit: {
          profiles: true,
          max_post_amount: true,
          post_per_period: true,
          skip_period: true,
          should_delete: true,
          should_pump: true,
          should_edit: true,
        },
    	});
		
  		setTimeout(() => {
  			this.update_data(this.props);	
  		}, 0)
    }

    check_if_pending(date) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/check-pending?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
        	NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {
        });
    }

    fromNow(date) {
    	// return moment(date).relativeTimeThreshold('m', 60);
    	return (moment(date)).fromNow();
    }

    togglUser(user_id, property_name) {
      var property_name = property_name || 'users';
       this.state.post.settings[property_name] = _.xor(this.state.post.settings[property_name], [user_id]);
       this.setState({
           post: this.state.post
       });
    }

    toggleAddGroup(group_id) {
      if (this.state.post.selected_groups.indexOf(group_id) > -1) {
        this.state.post.selected_groups.splice(this.state.post.selected_groups.indexOf(group_id), 1);
      } else {
        this.state.post.selected_groups.push(group_id);
      }

      this.setState({
        selected_groups: this.state.post.selected_groups,
      });
    }

    toggleAddPost(group_id) {
      if (this.state.post.selected_posts.indexOf(group_id) > -1) {
        this.state.post.selected_posts.splice(this.state.post.selected_posts.indexOf(group_id), 1);
      } else {
        this.state.post.selected_posts.push(group_id);
      }

      this.setState({
        selected_posts: this.state.post.selected_posts,
      });
    }

    getDisplayGroups(sort_by, search_text, amount_to_display, filter_by_profile, filter_query, selected, groups) {
      search_text = search_text || '';
      this.state.groups.map((g) => {
        g.follow_count = g.follow_count | 0;
      })

      var groups = _.orderBy(groups || this.state.groups, [sort_by, 'follow_count', 'today_slot_count', 'users_in_group_count'],['desc'])

      if (filter_query && filter_query.by_selected.value !== 'all') {

        groups = groups.filter((group) => {
          if (filter_query.by_selected.value === 'unselected') {
            return selected.indexOf(group._id) === -1;
          } else {
            return selected.indexOf(group._id) > -1;
          }
        })

        // 

      }

      if (filter_by_profile) {
        groups = groups.filter((group) => {
          return Object.keys(this.state.profile_group[filter_by_profile] || {}).indexOf(group._id) > -1
        })
      }

      return groups.filter((group) => {
          return group.name.toLowerCase().indexOf(search_text.toLowerCase()) > -1;
        })
        .slice(0,amount_to_display)

    }

    getCustomGroupSettings (time_slot, group_id) {
      time_slot.group_map[group_id] = time_slot.group_map[group_id] || {
        post_amount: null,
        active: false,
      };
      return time_slot.group_map[group_id];
    }

    getByGroupID (group_id) {
      if (Array.isArray(group_id)) {
        return group_id.map((_id) => {
          return this.state.groups.find((g) => g._id === _id);
        });
      }
      return this.state.groups.find((g) => g._id === group_id)
    }

    getByCampaignID (campaign_id) {
      if (Array.isArray(campaign_id)) {
        return campaign_id.map((_id) => {
          return this.state.campaigns.find((g) => g._id === _id);
        });
      }
      return this.state.campaigns.find((g) => g._id === campaign_id) || {}
    }

    calulateColumnPosts (time_slot) {

      var amount = Object.keys(time_slot.group_map).reduce((amount, group_id) => {
        if (time_slot.time && time_slot.group_map[group_id]) {
          if (time_slot.group_map[group_id].active) {
            amount += parseInt(time_slot.group_map[group_id].post_amount) || 1;
          }
        }
        return amount;
      }, 0);

      return amount;
    }

    calulateAllPosts () {
      var amount = 0;
      
      for (var i = 0; i < this.state.post.times.length; i++) {
        var time_slot = this.state.post.times[i];
        time_slot.group_map = time_slot.group_map || {};
        amount += Object.keys(time_slot.group_map).reduce((amount, group_id) => {
          if (time_slot.time && time_slot.group_map[group_id]) {
            if (time_slot.group_map[group_id].active) {
              amount += parseInt(time_slot.group_map[group_id].post_amount) || 1;
            }
          }
          return amount;
        }, 0);
      }

      return amount;
    }

    calulateGroupPosts (group_id) {
      var group = this.getByGroupID(group_id);

      var amount = this.state.post.times.reduce((amount, time) => {
        if (time.time && time.group_map[group_id]) {
          if (time.group_map[group_id].active) {
            amount += parseInt(time.group_map[group_id].post_amount) || 1;
          }
        }
        return amount;
      }, 0);

      return amount;
    }

    selectGroupRow (group_id) {
      var group = this.getByGroupID(group_id);
      this.state.post.times.map((time) => {
        var settings = this.getCustomGroupSettings(time, group_id);
        settings.active = true;
      });
      this.setState({post: this.state.post})
    }

    selectTimes (selected_list) {
      this.state.schedule_for_me.selected_list = selected_list;
      this.setState({schedule_for_me: this.state.schedule_for_me});
    }

    selectGroupColumn (slot) {
      // var group = this.getByGroupID(group_id);
      


      this.getDisplayGroups(this.state.sort_by, this.state.search_text, this.state.amount_to_display, null, null, [], this.getByGroupID(this.state.post.selected_groups)).map((group) => {
        slot.group_map[group._id] = slot.group_map[group._id] || {
          active: false,
          post_amount: 1,
        };
        slot.group_map[group._id].active = true;
      });
      // slot.times.map((time) => {
      //   // var settings = this.getCustomGroupSettings(time, group_id);
      //   // settings.active = true;
      // });
      this.setState({post: this.state.post})
    }

    unselect_all() {

      this.state.post.times.map((time) => {
        Object.keys(time.group_map).map((group_id) => {
          if (time.group_map[group_id]) {
            time.group_map[group_id].active = false;
          }
        })
      })

      this.setState({
        post: this.state.post,
      })

    }

    apply_schedule_for_me() {

      this.unselect_all();
      
      if (!this.state.schedule_for_me.selected_list || !this.state.schedule_for_me.selected_list.length) {
        return NotificationManager.error('no times selected');
      }
      var times = [];
      for (var i = 0; i < this.state.schedule_for_me.selected_list.length; i++) {
        var selected_time = this.state.schedule_for_me.selected_list[i];
        var time = this.state.post.times.find((t) => {
          return t.time === selected_time.id;
        });

        if (!time) {
          time = {time: selected_time.id, post_amount: 1, group_map: {}};
          this.state.post.times.push(time);
        }

        times.push(time);

        this.setState({post: this.state.post})
      }

        var groups = this.getDisplayGroups(this.state.sort_by, '', this.state.amount_to_display, null, null, [], this.getByGroupID(this.state.post.selected_groups)).map((g) => g._id)
        
        for (var n = 0; n < groups.length; n++) {
          var _times = [... new Set(times)];
          
          for (var i = 0; i < this.state.schedule_for_me.amount; i++) {
            
              var g_id = groups[n];
              
              var lowest_amount_column = Math.min.apply(Math, _times.map((time) => {
                return this.calulateColumnPosts(time);
              }));

              var __times = _times.filter((time) => {
                return lowest_amount_column === this.calulateColumnPosts(time);
              })

              var time = __times[Math.floor(Math.random()*__times.length)];
              _times.splice(_times.indexOf(time), 1)
              time.group_map[g_id] = {active: true}

              this.getCustomGroupSettings(time, g_id)

              time.group_map[g_id].active = true;
              this.setState({
                post: this.state.post,
              })


              // time.group_map
          }
        }

      // 
      // 


      // this.state.schedule_for_me.selected_list
    }

    render() {
    	const { t, i18n } = this.props;

        return (
		  <div className="overlay style-light">
              {(this.state.post._id || !this.state.selected) && this.state.campaigns && this.state.groups ? <div className="bg-primary p-5 text-white text-center rounded-3 " id="myForm">
                  
        <div className="pb-5 position-relative" id="">
          <h4 className="fs-5 fw-bolder text-uppercase text-center">{this.props.edit ? t('Schedule Post') : t('Schedule Post')}</h4>
          <a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {'schedule-new-post': undefined})}>×</a>
        </div>
        <div className="d-flex justify-content-center">

          {this.state.post.post_type === 'new' && <React.Fragment>
            <div className={`bd-highlight ${this.state.complete_steps.select_campaign() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'select_campaign' ? 'active' : '')} onClick={(e) => {this.state.current_step = 'select_campaign'; this.setState({ post: this.state.post })}}>
              <div className="icon"><h2 style={{padding: 0, margin: 0}}>1</h2><FaAd/></div>
              <strong>{t('Select Post Type')}</strong>
            </div>
            <div className={`bd-highlight ${this.state.complete_steps.select_posts() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'select_posts' ? 'active' : '')} onClick={(e) => {this.state.current_step = 'select_posts'; this.setState({ post: this.state.post })}}>
              <div className="icon"><h2 style={{padding: 0, margin: 0}}>2</h2><FaRegIdCard/></div>
              <strong>{t('Select Posts')}</strong>
            </div>
            <div className={`bd-highlight ${this.state.complete_steps.profiles() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'profiles' ? 'active' : '')} onClick={(e) => {this.state.current_step = 'profiles'; this.setState({ post: this.state.post })}}>
              <div className="icon"><h2 style={{padding: 0, margin: 0}}>3</h2><FaUserPlus/></div>
              <strong>{t('Select Profiles')}</strong>
            </div>
            <div className={`bd-highlight ${this.state.complete_steps.groups() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'groups' ? 'active' : '')} onClick={(e) => {this.state.current_step = 'groups'; this.setState({ post: this.state.post })}}>
              <div className="icon"><h2 style={{padding: 0, margin: 0}}>4</h2><FaUsers/></div>
              <strong>{t('Select Groups')}</strong>
            </div>
            <div className={`bd-highlight ${this.state.complete_steps.custom_groups() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'custom_groups' ? 'active' : '')} onClick={(e) => {this.state.current_step = 'custom_groups'; this.setState({ post: this.state.post })}}>
              <div className="icon"><h2 style={{padding: 0, margin: 0}}>5</h2><FaRegCalendarAlt/></div>
              <strong>{t('Schedule Groups')}</strong>
            </div>
            <div className={`bd-highlight ${this.state.complete_steps.settings() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'settings' ? 'active' : '')} onClick={(e) => {this.state.current_step = 'settings'; this.setState({ post: this.state.post })}}>
              <div className="icon"><h2 style={{padding: 0, margin: 0}}>6</h2><FaCog/></div>
              <strong>{t('Settings')}</strong>
            </div>
            <div className={`bd-highlight ${this.state.complete_steps.summary() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'summary' ? 'active' : '')} onClick={(e) => {this.state.current_step = 'summary'; this.setState({ post: this.state.post })}}>
              <div className="icon"><h2 style={{padding: 0, margin: 0}}>7</h2><FaChartBar/></div>
              <strong>{t('Summary')}</strong>
            </div>
          </React.Fragment>}


          {/*"select_type",
          "select_post_url",
          "select_comment_library",
          "profiles",
          "settings",
          "summary",*/}


          {this.state.post.post_type === 'existing' && <React.Fragment>
            <div className={`bd-highlight ${this.state.complete_steps.select_post_url() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'select_post_url' ? 'active' : '')} onClick={(e) => {this.state.current_step = 'select_post_url'; this.setState({ post: this.state.post })}}>
              <div className="icon"><h2 style={{padding: 0, margin: 0}}>1</h2><FaAd/></div>
              <strong>{t('Select Post Url')}</strong>
            </div>
            <div className={`bd-highlight ${this.state.complete_steps.select_comment_library() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'select_comment_library' ? 'active' : '')} onClick={(e) => {this.state.current_step = 'select_comment_library'; this.setState({ post: this.state.post })}}>
              <div className="icon"><h2 style={{padding: 0, margin: 0}}>2</h2><FaRegIdCard/></div>
              <strong>{t('Select Comment Library')}</strong>
            </div>
            <div className={`bd-highlight ${this.state.complete_steps.profiles() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'profiles' ? 'active' : '')} onClick={(e) => {this.state.current_step = 'profiles'; this.setState({ post: this.state.post })}}>
              <div className="icon"><h2 style={{padding: 0, margin: 0}}>3</h2><FaUserPlus/></div>
              <strong>{t('Select Profiles')}</strong>
            </div>
            <div className={`bd-highlight ${this.state.complete_steps.settings() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'settings' ? 'active' : '')} onClick={(e) => {this.state.current_step = 'settings'; this.setState({ post: this.state.post })}}>
              <div className="icon"><h2 style={{padding: 0, margin: 0}}>4</h2><FaCog/></div>
              <strong>{t('Settings')}</strong>
            </div>
            <div className={`bd-highlight ${this.state.complete_steps.summary() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'summary' ? 'active' : '')} onClick={(e) => {this.state.current_step = 'summary'; this.setState({ post: this.state.post })}}>
              <div className="icon"><h2 style={{padding: 0, margin: 0}}>5</h2><FaChartBar/></div>
              <strong>{t('Summary')}</strong>
            </div>
          </React.Fragment>}

                  </div>

                  <div className="pb-5 position-relative" id="signin_header" style={{minWidth: '700px'}}>


{this.state.current_step === 'select_type' && <div>
  <div>
    <h4>{t('Select Post Type')}</h4>
    <p>{t('The first step you must choose your post type, an existing post or a new one')}</p>

        <div className="form-group">
          <div className="input-group mb-3 align-center">
            <div className="input-group-prepend" style={{margin: 'auto'}}>
              
              <span data-tip={t('Coming soon')} onClick={() => { return; this.state.post.post_type = 'new'; this.setState({post: this.state.post}); this.next(this.state.post.post_type); }} className="input-group-text" id="basic-addon1" style={{
                padding: 0,
                margin: 0,
                color: this.state.post.post_type === 'new' ? 'rgb(35 151 66)' : '#000',
                fontSize: '32px',

                color: 'red',
              }}>{t('New Post')}</span>

              <span onClick={() => { this.state.post.post_type = 'existing'; this.setState({post: this.state.post}); this.next(this.state.post.post_type); }} className="input-group-text" id="basic-addon1" style={{
                padding: 0,
                margin: 0,
                color: this.state.post.post_type === 'existing' ? 'rgb(35 151 66)' : '#000',
                fontSize: '32px',
              }}>{t('Existing Post')}</span>
            </div>
          </div>
        </div>
  </div>
</div>}

{this.state.current_step === 'select_post_url' && <div>
  <div>
    <h4>{t('Select Post URL')}</h4>
    <div className="input-group-prepend">
      <input className="form-control" type="text" value={this.state.post.post_url} onChange={(e) => { this.state.post.post_url = e.target.value; this.setState({post: this.state.post}) }}/>
    </div>
    {this.get_post_id_from_url(this.state.post.post_url) && <div>
      <div>{t('Embed will only show if post is public')}</div>
      {<iframe
        src={"https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FNetanyahu%2Fposts%2F"+this.get_post_id_from_url(this.state.post.post_url)+"&show_text=false&width=500"} 
        width="500" 
        height="202" 
        style={{
          border: 'none', 
          overflow: 'hidden'
        }} 
        scrolling="no" 
        frameborder="0" 
        allowfullscreen="true" 
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe>}
    </div>}
  </div>
</div>}
{this.state.current_step === 'select_comment_library' && <div>
  <div>
    <h4>{t('Select Comments')}</h4>

    <div className={"row"}>
      {this.getDisplayComments(this.state.sort_by, this.state.search_text, this.state.amount_to_display, this.state.filter_by_profile, this.state.filter_query, this.state.selected).map((group) => (<div style={{padding: '13px', width: '25%', float: 'left'}}>

      <span dir="ltr" className={"text-decoration-none my-1 d-block square group " }>
        <div className="">
          <span className="position-absolute top-50 start-50 translate-middle bg-dark-75 w-75 h-75">
            <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder">
              {group.name} ({group.advanced_comments.length})
              <br/>
              <button className={`btn  ${this.state.post.settings.comment_group == group._id ? 'btn-success': 'btn-primary'}`} onClick={() => {this.state.post.settings.comment_group = group._id; this.setState({post: this.state.post})}}>{this.state.post.settings.comment_group == group._id ? t('Selected'): t('Select Comment')}</button>
            </p>
          </span>
        </div>
      </span>
        
      </div>))}
    </div>
  </div>
</div>}

{this.state.current_step === 'select_campaign' && <div>
  <div>
    <h4>{t('Select Campaign')}</h4>
    <p>{t('The first step you must choose your campaign')}</p>
    <select className="form-control" value={this.state.post.campaign._id} onChange={(e) => { this.state.post.campaign = this.state.campaigns.find((c) => c._id == e.target.value); this.setState({post: this.state.post}) }}>
      <option></option>
      {this.state.campaigns.map((campaign)=>(<option value={campaign._id}>{campaign.name}</option>))}
    </select>
  </div>
</div>}

{this.state.current_step === 'select_posts' && <div>
  <h4>{t('Select posts')}</h4>
  <p>{t('In this step we will choose the posts that we want to publish within the campaign that we have selected')}</p>

  <div>
      {t('All posts')}
      <Switch
        classes={this.props.classes}
        checked={this.state.post.settings.all_posts}
        onClick={(e) => { this.state.post.settings.all_posts = !this.state.post.settings.all_posts; this.setState({
          post: this.state.post,
        }) }}
      />
  </div>

  {!this.state.post.settings.all_posts && <div>
  <span>({this.state.post.selected_posts.length} {t('Posts selected')})</span>


    {/*<input className="form-control" placeholder={t('Search Groups')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>*/}

    <div style={{overflowY: 'scroll', height: '500px', border: '1px solid black'}}>
      {this.state.posts.map((post) => (<div style={{padding: '13px', width: '33%', float: 'left'}}>
        <span dir="ltr" className={"text-decoration-none my-1 d-block square post " + (this.state.selected.indexOf(post._id) > -1 ? ' active': '') }>
          <div className="rounded rounded-circle ">
            {post.media_url && <img style={{left: 0}} className="post-image text-center img img-thumbnail img-fluid " src={post.media_url.split(',')[0]} g="img/post.png" alt="" />}
            {(post.type === 'text-background' || post.type === 'text') && <span style={{backgroundColor: '#d37554'}} className="position-absolute top-50 start-50 translate-middle bg-dark-75 w-75 h-75">
              <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder">
                {post.post_content}
              </p>
            </span>}
          </div>
        </span>
        <button onClick={this.toggleAddPost.bind(this, post._id)} className={`btn ${(this.state.post.selected_posts.indexOf(post._id) > -1 ? 'btn-danger': 'btn-success')}`}>{(this.state.post.selected_posts.indexOf(post._id) > -1 ? t('Unselect'): t('Select'))}</button>
      </div>))}
    </div>
  </div>}


</div>}

{this.state.current_step === 'schedule' && <div>
  <div>
    <h4>{t('At what times?')}</h4>

    {this.state.post.times.map((time_slot, index) => (<div className="input-group mb-3">
      {/*<div className="input-group-prepend">
        <button className="btn btn-success">+</button>
      </div>*/}

      <div className="input-group-prepend">
        <button disabled={this.state.post.times.length == 1} className="btn btn-danger" onClick={(e) => {
          this.state.post.times.splice(index, 1)
          this.setState({post: this.state.post})
        }}>-</button>
      </div>
      <div className="input-group-prepend">
        <input className="form-control" type="number" value={time_slot.post_amount} onChange={(e) => { time_slot.post_amount = Math.max(e.target.value, 1); this.setState({post: this.state.post}) }}/>
      </div>
      <div class="input-group-prepend">
        <span class="input-group-text">{t('Post Amount')}</span>
      </div>

      <select className="form-control" value={this.state.post.time_slot} value={time_slot.time} onChange={(e) => { time_slot.time = e.target.value; this.setState({post: this.state.post}) }}>
        <option></option>
        {this.state.times.map((time)=>(<option value={time}>{time}:00</option>))}
      </select>

      {/*<div className="input-group-append">
        <span className="input-group-text">.00</span>
      </div>*/}
    </div>))}



    <button className="btn btn-success" onClick={(e) => {
      this.state.post.times.push({time: null, post_amount: 1, group_map: {}});
      this.setState({post: this.state.post})
    }}>{t('Add Time')}</button>
  </div>
</div>}
{this.state.current_step === 'groups' && <div>
  <div>
    <h4>{t('Select Groups')}</h4>
    <p>{t('In this step we will choose the facebook groups where we want to publish the posts')}</p>







                  <div>
                    <div style={{width: '50%', float: 'left'}}>
                      <h3>{t('Sort By')}</h3>

                      <select value={this.state.sort_by} onChange={(e) => { this.setState({sort_by: e.target.value}); }} className="form-select bg-primary  rounded-3 text-white py-3 mb-4 px-1" aria-label="Default select example">
                        <option>{t('Sort By')}</option>
                        <option value={'today_slot_count'}>{t('Slot Count')}</option>
                        <option value={'users_in_group_count'}>{t('User in group')}</option>
                        <option value={'follow_count'}>{t('Followers')}</option>
                      </select>

                    </div>
                    <div style={{width: '50%', float: 'left'}}>
                      <h3>{t('Filter By')}</h3>

                      <div className="btn-group">
                        <a onClick={() => { this.state.filter_query.by_selected.value = 'all'; this.setState({filter_query: this.state.filter_query}) }}  className={`btn ${this.state.filter_query.by_selected.value === 'all' ? 'btn-success' : 'btn-danger'}`}>{t('All')}</a>
                        <a onClick={() => { this.state.filter_query.by_selected.value = 'selected'; this.setState({filter_query: this.state.filter_query}) }} className={`btn ${this.state.filter_query.by_selected.value === 'selected' ? 'btn-success' : 'btn-danger'}`}>{t('Selected')}</a>
                        <a onClick={() => { this.state.filter_query.by_selected.value = 'unselected'; this.setState({filter_query: this.state.filter_query}) }}  className={`btn ${this.state.filter_query.by_selected.value === 'unselected' ? 'btn-success' : 'btn-danger'}`}>{t('Unselected')}</a>
                      </div>
                    </div>
                  </div>

                  <div style={{clear: 'both'}}></div>









              <div className="input-group">
                <button className="btn btn-primary" onClick={this.selectAll.bind(this, this.getDisplayGroups(this.state.sort_by, this.state.search_text, this.state.amount_to_display, this.state.filter_by_profile, this.state.filter_query, this.state.post.selected_groups))}>{t('Select All')} ({this.getDisplayGroups(this.state.sort_by, this.state.search_text, this.state.amount_to_display, this.state.filter_by_profile, this.state.filter_query, this.state.post.selected_groups).length})</button>
                <input className="form-control" placeholder={t('Search Groups')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>
              </div>

              <div className="group-info"><strong>{t(`Showing`)}:</strong> {this.getDisplayGroups(this.state.sort_by, this.state.search_text, this.state.amount_to_display, this.state.filter_by_profile, this.state.filter_query, this.state.post.selected_groups).length} {t(`groups`)} {t('of')} {this.state.groups && this.state.groups.length}</div>
                  <span>({this.state.post.selected_groups.length} {t('groups selected')})</span>


    <div style={{overflowY: 'scroll', height: '500px', border: '1px solid black'}}>
      {this.getDisplayGroups(this.state.sort_by, this.state.search_text, this.state.amount_to_display, this.state.filter_by_profile, this.state.filter_query, this.state.post.selected_groups).map((group) => (<div style={{padding: '13px', width: '20%', float: 'left'}}>
        <span dir="ltr" className={"text-decoration-none my-1 d-block square group " + (this.state.selected.indexOf(group._id) > -1 ? ' active': '') }>
          <div className="rounded rounded-circle ">
            <img style={{left: 0}} className="group-image img img-thumbnail img-fluid rounded rounded-circle " src={group.image_url} g="img/group1.png" alt="" />
            <span className="position-absolute top-50 start-50 translate-middle bg-dark-75 rounded rounded-circle w-75 h-75">
              <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder"><a target="_blank" href={`https://facebook.com/groups/${group.group_id}`}>{group.name}</a></p>
            </span>
          </div>
          <span className="badge bg-info px-2 py-2 small text-white rounded-pill position-25"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/follower.e4d49cda.svg" g="icons/follower.svg" />{nFormatter(group.follow_count, 2)}</span><span className="badge bg-info px-2 py-2 small text-white rounded-pill position-75"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/clock.66a84231.svg" g="icons/clock.svg" />{group.today_slot_count}<img className="d-inline mx-1 h-icon" width="5px" src="/static/media/account.9ec423dc.svg" g="icons/account.svg" />{group.users_in_group_count}</span>
        </span>
          <button onClick={this.toggleAddGroup.bind(this, group._id)} className={`btn ${(this.state.post.selected_groups.indexOf(group._id) > -1 ? 'btn-danger': 'btn-success')}`}>{(this.state.post.selected_groups.indexOf(group._id) > -1 ? t('Unselect'): t('Select'))}</button>
      </div>))}
    </div>
  </div>
</div>}
{this.state.current_step === 'custom_groups' && <div>


      <h4>{t('Schedule Groups')}</h4>
      <p>{t("In this step we will set a custom schedule for posts in groups")}</p>
      {this.state.post.times.length === 0 && <p style={{color: 'black'}}>{t("You must add slot times")}</p>}

      <hr/>
      <div className="schedule-for-me" style={{direction: 'rtl'}}>
        <h4>{t('Schedule for me')}</h4>
        {t('Schedule for me')} <input type="number" value={this.state.schedule_for_me.amount} onChange={(e) => { this.state.schedule_for_me.amount = Math.max(e.target.value, 1); this.setState({schedule_for_me: this.state.schedule_for_me})}} /> {t('posts in each group')} {t('disperse at')} 


         <Multiselect
          options={this.state.times.map( (time) => {
            return {
              name: `${time}:00`,
              id: time,
            }
          } )} 
          selectedValues={this.state.schedule_for_me.selected_list} 
          style={{
            backgroundColor: '#fff',
            width: '300px',
            display: 'inline-block',
          }}
          className={'adad'}
          onSelect={ this.selectTimes.bind(this) }
          onRemove={this.selectTimes.bind(this)} 
          displayValue="name" 
        />

 {/*{t('sorting by')} <select value={this.state.sort_by} onChange={(e) => { this.setState({sort_by: e.target.value}); }} className="" aria-label="Default select example">
          <option>{t('Sort By')}</option>
          <option value={'today_slot_count'}>{t('Slot Count')}</option>
          <option value={'users_in_group_count'}>{t('User in group')}</option>
          <option value={'follow_count'}>{t('Followers')}</option>
        </select>*/}
        <button onClick={this.apply_schedule_for_me.bind(this)}>{t('Apply')}</button>
        </div>
      <hr/>
        <h4>{t('Custom')}</h4>

      <table className="table group-selection">
        <thead>
          <tr>
            <th scope="col">
              <h3>{t('Sort By')}</h3>

              <select value={this.state.sort_by} onChange={(e) => { this.setState({sort_by: e.target.value}); }} className="form-select bg-primary  rounded-3 text-white py-3 mb-4 px-1" aria-label="Default select example">
                <option>{t('Sort By')}</option>
                <option value={'today_slot_count'}>{t('Slot Count')}</option>
                <option value={'users_in_group_count'}>{t('User in group')}</option>
                <option value={'follow_count'}>{t('Followers')}</option>
              </select>

              <div className="input-group">
                <input className="form-control" placeholder={t('Search Groups')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>
              </div>

            </th>
            {_.orderBy(this.state.post.times, ['time']).map((time_slot, index) => (<th scope="col">
              <button onClick={this.selectGroupColumn.bind(this, time_slot)}>{t('Select All')}</button>

              <button onClick={() => { this.state.post.times.splice(this.state.post.times.indexOf(time_slot), 1); this.setState({post: this.state.post}) }}>{t('Delete')}</button>
              

              <div className="input-group">
                <select className="form-control" value={this.state.post.time_slot} value={time_slot.time} onChange={(e) => { time_slot.time = parseInt(e.target.value); this.setState({post: this.state.post}) }}>
                  <option></option>
                  {this.state.times.map((time)=>(<option disabled={this.state.post.times.find((t) => time_slot.time !== time && t.time === time)} value={time}>{time}:00</option>))}
                </select>

                <div>
                  <span>סה"כ <strong>{this.calulateColumnPosts(time_slot)}</strong> פוסטים בשעה זו</span>
                </div>
              </div>


             </th>))}
            <th scope="col" style={{width: '180px'}}>
              <div>
                <span>סה"כ <strong>{this.calulateAllPosts()}</strong> פוסטים מתוזמנים</span>
              </div>
              <button className={`btn btn-success ${this.state.post.times.length === 0 ? 'blink-bg' : ''}`} onClick={(e) => {
                this.state.post.times.push({time: null, post_amount: 1, group_map: {}});
                this.setState({post: this.state.post})
              }}>{t('Add Time')}</button>

            </th>
            <th style={{whiteSpace: 'nowrap'}}></th>
          </tr>
        </thead>
        <tbody>



          {this.getDisplayGroups(this.state.sort_by, this.state.search_text, this.state.amount_to_display, null, null, [], this.getByGroupID(this.state.post.selected_groups)).map((group) => <tr>
            <td scope="row" style={{width: "180px"}}>
              {(<div style={{}}>
                <span dir="ltr" className={"text-decoration-none my-1 d-block square group " + (this.state.selected.indexOf(group._id) > -1 ? ' active': '') }>
                  <div className="rounded rounded-circle ">
                    <img style={{left: 0}} className="group-image img img-thumbnail img-fluid rounded rounded-circle " src={group.image_url} g="img/group1.png" alt="" />
                    <span className="position-absolute top-50 start-50 translate-middle bg-dark-75 rounded rounded-circle w-75 h-75">
                      <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder"><a target="_blank" href={`https://facebook.com/groups/${group.group_id}`}>{group.name}</a></p>
                    </span>
                  </div>
                  <span className="badge bg-info px-2 py-2 small text-white rounded-pill position-25"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/follower.e4d49cda.svg" g="icons/follower.svg" />{nFormatter(group.follow_count, 2)}</span><span className="badge bg-info px-2 py-2 small text-white rounded-pill position-75"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/clock.66a84231.svg" g="icons/clock.svg" />{group.today_slot_count}<img className="d-inline mx-1 h-icon" width="5px" src="/static/media/account.9ec423dc.svg" g="icons/account.svg" />{group.users_in_group_count}</span>
                </span>
              </div>)}
            </td>
            {_.orderBy(this.state.post.times, ['time']).map((time_slot, index) => (<td scope="col" style={{width: "100px"}}>
                {time_slot.time && <div>
                  <div>
                    <Switch
                      classes={this.props.classes}
                      checked={this.getCustomGroupSettings(time_slot, group._id).active}
                      onClick={(e) => {
                        time_slot.group_map[group._id].active = !this.getCustomGroupSettings(time_slot, group._id).active;
                        this.setState({
                          post: this.state.post,
                        })
                      }}
                    />
                  </div>

                  {time_slot.group_map[group._id].active && <div>
                    <input 
                      className="form-control"
                      type="number"
                      value={time_slot.group_map[group._id].post_amount || 1}
                      min="1"
                      style={{maxWidth: '100%'}}
                      onChange={(e) => { 
                          time_slot.group_map[group._id].post_amount = e.target.value; 
                          this.setState({
                            custom_group_settings: time_slot.group_map,
                          })
                      }}
                    />
                  </div>}
                </div>}

            </td>))}
            <td>
              <button onClick={this.selectGroupRow.bind(this, group._id)}>{t('Select All')}</button>
              <div>
                <span>סה"כ <strong>{this.calulateGroupPosts(group._id)}</strong> פוסטים בקבוצה זו</span>
              </div>
            </td>
          </tr>)}
        </tbody>
      </table>


  <div>
  {/*
    <h4>{t('Custom Group setttings')}</h4>

        <div className="d-flex justify-content-center custom-groups">
          {this.state.post.times.map((time_slot, index) => (<div className="custom-time-column">
            <div className={`bd-highlight custom-time `}>
              <div className="icon"><h2 style={{padding: 0, margin: 0}}>{time_slot.time}:00</h2></div>
            </div>
            
            <div className={`buttons `}>
              <button class="btn btn-sm btn-primary">{t('Select All')}</button>
            </div>

            {this.state.post.selected_groups.map((group_id) => <div>
              <div className="group">
                
                <div>
                  <span 
                    onClick={(e) => {
                        time_slot.group_map[group_id].active = !this.getCustomGroupSettings(time_slot.time, group_id).active;
                        this.setState({
                          post: this.state.post,
                        })
                      }}
                    >{this.getByGroupID(group_id).name}</span>
                  
                  <Switch
                    classes={this.props.classes}
                    checked={this.getCustomGroupSettings(time_slot.time, group_id).active}
                    onClick={(e) => {
                      time_slot.group_map[group_id].active = !this.getCustomGroupSettings(time_slot.time, group_id).active;
                      this.setState({
                        post: this.state.post,
                      })
                    }}
                  />
                </div>

                {time_slot.group_map[group_id].active && <div>
                  <input 
                    className="form-control"
                    type="number"
                    value={time_slot.group_map[group_id].post_amount || time_slot.post_amount}
                    min="1"
                    onChange={(e) => { 
                        time_slot.group_map[group_id].post_amount = e.target.value; 
                        this.setState({
                          custom_group_settings: time_slot.group_map,
                        })
                    }}
                  />
                </div>}

              </div>
            </div>)}

          </div>))}
        </div>
  */}

  </div>
</div>}
{this.state.current_step === 'profiles' && <div>
  <div>
    <h4>{t('From what profiles?')}</h4>

    <p>{t('In this step we will choose the profiles that will publish the posts in the groups, you can select all profiles')}</p>

    {this.state.allow_edit.profiles && <div>
          <button className="btn btn-warning" onClick={() => {
             this.state.post.settings.comment_users = this.state.profiles.map((profile) => profile._id);
             this.setState({
                 users: this.state.post
             });
          }}>{t("Mark All")}</button>
    {this.state.profiles.map((profile) => (<React.Fragment><span className="position-relative"><span data-tip={profile.first_name + " " + profile.last_name} onClick={this.togglUser.bind(this, profile._id, 'comment_users')} className={"me-3 " + ((this.state.post.settings.comment_users.indexOf(profile._id) > -1) ? 'active' : '')} key={profile._id}>
      <img width="60px" className={`profile rounded rounded-circle border border-3  ${profile.is_logged_in ? 'logged_in' : 'logged_out'} `} src={'/api/users/profile/' + profile._id} g="img/user1.png" alt="" />
    </span></span></React.Fragment>))}
    </div>}
  </div>
</div>}
{this.state.current_step === 'settings' && <div>
  {<div className="settings">

      <h4>{t('Settings')}</h4>

      <p>{t('In this step you can select custom settings if needed (not required)')}</p>


      {this.state.post.post_type === 'existing' && <MuiPickersUtilsProvider utils={DateFnsUtils}>

        <div className="form-group">
          <h4 htmlFor="exampleFormControlTextarea1">{t('Reply Options')}</h4>

          <div>
              {t('Immediate Pump')}
              <Switch
                checked={this.state.post.settings.immediate_pump}
                onClick={(e) => { this.state.post.settings.immediate_pump = !this.state.post.settings.immediate_pump; this.setState({
                  post: this.state.post,
                }) }}
              />
          </div>

          {!this.state.post.immediate_pump && <div>
              {t('Max Comments')}
              <input 
                type="number"
                name="max_comments"
                value={this.state.post.settings.max_comments || 0}
                min="0"
                onChange={(e) => {
                  this.state.post.settings.max_comments = e.target.value;

                    this.setState({
                      post: this.state.post,
                    })
                }}
              />
          </div>}


          {!this.state.post.immediate_pump && <div>
              {t('Max Immediate Comment Count')}
              <input 
                type="number"
                name="max_immediate_comment_count"
                value={this.state.post.settings.max_immediate_comment_count || 0}
                min="0"
                onChange={(e) => {
                  this.state.post.settings.max_immediate_comment_count = e.target.value;
                    this.setState({
                      post: this.state.post,
                    })
                }}
              />
          </div>}

          {!this.state.post.immediate_pump && <div>
              {t('Comment duration hours')}
              <input 
                type="number"
                name="comment_duration_hours"
                value={this.state.post.settings.comment_duration_hours || 0}
                min="0"
                onChange={(e) => { 
                    this.state.post.settings.comment_duration_hours = e.target.value;
                    this.setState({
                      post: this.state.post,
                    })
                }}
              />
          </div>}


        </div>

        <div>
          <h4>{t('From what profile should we reply from?')}</h4>

          {this.state.allow_edit.profiles && <div>
                <button className="btn btn-warning" onClick={() => {
                   this.state.post.settings.reply_users = this.state.profiles.map((profile) => profile._id);
                   this.setState({
                       post: this.state.post
                   });
                }}>{t("Mark All")}</button>
          {this.state.profiles.map((profile) => (<React.Fragment><span className="position-relative"><span data-tip={profile.first_name + " " + profile.last_name} onClick={this.togglUser.bind(this, profile._id, 'reply_users')} className={"me-3 " + ((this.state.post.settings.reply_users.indexOf(profile._id) > -1) ? 'active' : '')} key={profile._id}>
            <img width="60px" className={`profile rounded rounded-circle border border-3  ${profile.is_logged_in ? 'logged_in' : 'logged_out'} `} src={'/api/users/profile/' + profile._id} g="img/user1.png" alt="" />
          </span></span></React.Fragment>))}
          </div>}
        </div>

        <div className="form-group">
          <h4 htmlFor="exampleFormControlTextarea1">{t('Reply Text')}</h4>

          <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} defaultValue={""} onChange={event => {this.state.post.settings.reply_text = event.target.value; this.setState({ post: this.state.post })}} value={this.state.post.settings.reply_text}/>
        </div>


      </MuiPickersUtilsProvider>}
      
      {this.state.post.post_type === 'new' && <MuiPickersUtilsProvider utils={DateFnsUtils}>

        {this.state.allow_edit.should_delete && <div>
          <div>
              {t('Should Delete At Specific Time')}
              <Switch
                classes={this.props.classes}
                checked={this.state.post.settings.should_delete}
                onClick={(e) => { this.state.post.settings.should_delete = !this.state.post.settings.should_delete; this.setState({
                  post: this.state.post,
                }) }}
              />
          </div>

          {this.state.post.settings.should_delete && <TimePicker
            label={t("Delete At")}
            inputVariant="outlined"
            value={this.state.post.settings.delete_at}
            onChange={ (newValue)=> {
                this.state.post.settings.delete_at = newValue;
                this.setState({
                    post: this.state.post,
                })
            }}
          />}
        </div>}

        {this.state.allow_edit.should_delete && <div>
          <div>
              {t('Should Delete Time After Publish')}
              <Switch
                classes={this.props.classes}
                checked={this.state.post.settings.should_delete_time_after_publish}
                onClick={(e) => { this.state.post.settings.should_delete_time_after_publish = !this.state.post.settings.should_delete_time_after_publish; this.setState({
                  post: this.state.post,
                }) }}
              />
          </div>

          {this.state.post.settings.should_delete_time_after_publish && <select value={this.state.post.settings.delete_after_hours} onChange={(e) => {
                  this.state.post.settings.delete_after_hours = e.target.value; 
                  this.setState({
                    post: this.state.post,
                  })
            }}>
                <option value={1}>{t('hour')}</option>
                <option value={2}>2 {t('hours')}</option>
                <option value={4}>4 {t('hours')}</option>
                <option value={6}>6 {t('hours')}</option>
                <option value={8}>8 {t('hours')}</option>
                <option value={10}>10 {t('hours')}</option>
                <option value={12}>12 {t('hours')}</option>
                <option value={16}>16 {t('hours')}</option>
                <option value={20}>20 {t('hours')}</option>
                <option value={24}>{t('day')}</option>
                <option value={24*2}>2 {t('days')}</option>
                <option value={24*3}>3 {t('days')}</option>
                <option value={24*4}>4 {t('days')}</option>
                <option value={24*5}>5 {t('days')}</option>
                <option value={24*6}>6 {t('days')}</option>
                <option value={24*7}>1 {t('week')}</option>
            </select>}
        </div>}

        {this.state.allow_edit.should_pump && <div>
          <div>
              <span data-tip={t('Comments a dot from a random profiles and deletes')}><FaRegQuestionCircle/></span> {t('Should Pump')}
              <Switch
                classes={this.props.classes}
                checked={this.state.post.settings.should_pump}
                onClick={(e) => { this.state.post.settings.should_pump = !this.state.post.settings.should_pump; this.setState({
                  post: this.state.post,
                }) }}
              />
          </div>
          {this.state.post.settings.should_pump && <div>
              {t('Pump Every')}

              <select value={this.state.post.settings.pump_every_minutes} onChange={(e) => {
                    this.state.post.settings.pump_every_minutes = e.target.value; 
                    this.setState({
                      post: this.state.post,
                    })
              }}>
                  <option value={60}>{t('hour')}</option>
                  <option value={60*1.5}>1.5 {t('hours')}</option>
                  <option value={60*2}>2 {t('hours')}</option>
                  <option value={60*2.5}>2.5 {t('hours')}</option>
                  <option value={60*3}>3 {t('hours')}</option>
                  <option value={60*4}>4 {t('hours')}</option>
                  <option value={60*5}>5 {t('hours')}</option>
                  <option value={60*6}>6 {t('hours')}</option>
                  <option value={60*7}>7 {t('hours')}</option>
                  <option value={60*8}>8 {t('hours')}</option>
                  <option value={60*9}>9 {t('hours')}</option>
                  <option value={60*10}>10 {t('hours')}</option>
              </select>
          </div>}
        </div>}

        {this.state.allow_edit.should_edit && <div>
          <div>
              {t('Should Edit')}
              <Switch
                classes={this.props.classes}
                checked={this.state.post.settings.should_edit}
                onClick={(e) => { this.state.post.settings.should_edit = !this.state.post.settings.should_edit; this.setState({
                  post: this.state.post,
                }) }}
              />
          </div>
          {this.state.post.settings.should_edit && <div>
              {t('Edit After Minutes')}
              <input 
                type="number"
                name="edit_after_minutes"
                value={this.state.post.settings.edit_after_minutes}
                min="0"
                onChange={(e) => { 
                    this.state.post.settings.edit_after_minutes = e.target.value; 
                    this.setState({
                      post: this.state.post,
                    })
                }}
              />
          </div>}
        </div>}

        {this.state.post.settings.should_edit && <div>
            {t('Campaign')}
            <select value={this.state.post.settings.edit_campaign} onChange={(e) => {
                  this.state.post.settings.edit_campaign = e.target.value; 
                  this.setState({
                    post: this.state.post,
                  })
            }}>
                <option></option>
                {this.state.campaigns.map((campaign) => <option value={campaign._id}>{campaign.name}</option>)}
            </select>

                <div className="select-edit-posts">
                  {this.state.edit_campaign_posts.map((post) => (
                      <React.Fragment>

                          <span className={`position-relative select-edit-post ${(this.state.post.settings.edit_posts || []).indexOf(post._id) > -1 ? 'selected' : ''}`} onClick={() => {
                                this.state.post.settings.edit_posts = this.state.post.settings.edit_posts || []; 
                                var current_index = this.state.post.settings.edit_posts.indexOf(post._id);
                                if (current_index > -1) {
                                  this.state.post.settings.edit_posts.splice(current_index, 1)
                                } else {
                                  this.state.post.settings.edit_posts.push(post._id); 
                                }

                                this.setState({
                                  post: this.state.post,
                                })
                          }}>
                          <span className="text-background d-block m-1 border border-2 border-secondary text-center position-relative post" >

                                  {(!post.type || post.type === 'image') && 
                                    <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                                  }

                                  {(post.type === 'video') && 
                                    <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                                  }

                                  {(post.type === 'album') && 
                                    <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                                  }

                                  {(post.type === 'text-background' || post.type === 'text') && 
                                    <span className="text-background">
                                       {post.post_content}
                                    </span>
                                  }
                          </span>



                      </span>

                      </React.Fragment>
                  ))}
                 </div>

        </div>}



      </MuiPickersUtilsProvider>}


    <br/>
    <br/>
  </div>}

</div>}
{this.state.current_step === 'summary' && <div>

      <h4>{t('Summary')}</h4>
      <p style={{direction: "rtl"}}>{t('Congratulations! you have successfully scheduled an existing post')}</p>
      
      <div className="summary">
        {<iframe
          src={"https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FNetanyahu%2Fposts%2F"+this.get_post_id_from_url(this.state.post.post_url)+"&show_text=false&width=500"} 
          width="500" 
          height="202" 
          style={{
            border: 'none', 
            overflow: 'hidden'
          }} 
          scrolling="no" 
          frameborder="0" 
          allowfullscreen="true" 
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>}

      </div>
      <br/>
      <p style={{color: 'lime', fontSize: '24px'}}><strong>{t("Don't forget to save your settings")}</strong></p>

</div>}




                  </div>



        <hr/>
          {(this.state.post._id || this.state.current_step === 'summary') && <button onClick={this.save.bind(this)} type="submit" className="btn w-75 fw-bold bg-white py-3 px-5 mt-4 text-primary rounded rounded-pill">{t('Save')}</button>}
          {this.state.post._id && <button onClick={this.delete.bind(this)} type="submit" className="btn w-75 fw-bold btn-danger py-3 px-5 mt-4 text-primary rounded rounded-pill">{t('Delete')}</button>}
          {this.state.current_step !== 'select_type' && this.state.current_step !== 'summary' && <button disabled={!this.state.complete_steps[this.state.current_step]()} onClick={this.next.bind(this)} type="submit" className="btn w-75 fw-bold bg-white py-3 px-5 mt-4 text-primary rounded rounded-pill">{t('Next')}</button>}
        <hr/>



         </div> : <div className="loader">
                  <svg width="300px" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                    <circle fill="none" stroke="#fff" strokeWidth={4} cx={50} cy={50} r={44} style={{opacity: '0.5'}} />
                    <circle fill="#fff" stroke="#e74c3c" strokeWidth={3} cx={8} cy={54} r={6}>
                      <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 48" to="360 50 52" repeatCount="indefinite" />
                    </circle>
                  </svg>
                 </div>}
		  </div>
        );
    }
}

SchedulePostPopup.propTypes = {};

SchedulePostPopup.defaultProps = {};

export default SchedulePostPopup;



