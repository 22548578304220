import React from 'react';
import PropTypes from 'prop-types';
import fetch from 'node-fetch';
import _ from 'lodash';
import { TagsInput } from "react-tag-input-component";
import Select from 'react-select'

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import { FaCheck } from "react-icons/fa";

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { SketchPicker } from 'react-color'
import { confirmAlert } from 'react-confirm-alert'; // Import

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useParams,
  useLocation
} from "react-router-dom";

import {Radio, RadioGroup} from "@material-ui/core";

import queryString from 'query-string';

import Slider from '@material-ui/core/Slider';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

Object.defineProperty(Array.prototype, 'chunk_inefficient', {
  value: function(chunkSize) {
    var array = this;
    return [].concat.apply([],
      array.map(function(elem, i) {
        return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
      })
    );
  }
});


function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}


const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);


class GroupSearch extends React.Component {

    state = {
      city_options: [
      ],
      "public_libraries": [],
      "base_groups": [],
      "libraries": [],
      "groups": [],
      "group_library": {},
      "allow_permissions": [],
      "selected": null,
      "amount_to_display": 300,
      "search_text": [],
      "search_cities": [],
      // "sort_by": 'follow_count',
      "sort_by": null,
      // "sort_by": 'users_in_group_count',
      "profiles": [],
      "filter_by_profile": null,
      "profile_group": {},
      "loading": true,
      "filter_query": {
        by_selected: {
          value: 'all',
          query: {},
        },
      },
    }

    refresh() {
    }
    componentWillReceiveProps() {
    }
    componentWillMount() {
      this.update_data(this.props);
      this.get_groups(this.props);
    }

    getHighLowFollowerCount() {

      // return {
      //   high: this.state.groups.sort((a, b) => {
      //     follow_count
      //   })
      // }

    }

    get_groups(props) {
      var token = localStorage.getItem('user_token');

          this.setState({
            loading: true,
            groups: [],
          });

          try {
            if (this.get_groups_conroller) {
              this.get_groups_conroller.abort();
            }
          } catch {
          }
        this.get_groups_conroller = new AbortController();
        let signal = this.get_groups_conroller.signal;

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/groups?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            base_groups: data,
          });
        })
        .catch(err => {

        });


        setTimeout(async () => {
          try {
            var response = await fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/groups?all=true&access_token=${token}`, {
              method: 'POST',
              signal: signal,
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                search_text: this.state.search_text,
                selected_groups: this.props.selected,
                filter_by: this.state.filter_query.by_selected.value,
                search_cities: this.state.search_cities,
                sort_by: this.state.sort_by,
                filter_by_profile: this.state.filter_by_profile,
                filter_by_library: this.state.filter_by_library,
                page: 1,
                group_status: this.state.filter_query.group_status,
                has_group_settings: this.props.groupSettings,
              })
            });
            let data = await response.json();

            this.state.groups = data
            this.setState({
              groups: this.state.groups,
              loading: false,
              total_group_count: await response.headers.get('total_group_count'),
            });
          } catch (e) {

          }
        })

    }
    scanGroup(group_id) {

      var token = localStorage.getItem('user_token');
      var query = queryString.parse(this.props.location.search);
      
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/scan-group?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            profile: query.profile,
            group: group_id,
          })
        })
        .then(response => response.json())
        .then(data => {

          NotificationManager.success('added to scan group que');
          // this.props.updateSearch({path: [':lang', 'console'], "new-profile": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('error adding to scan group que');
        })

    }

    update_data(props) {
      var token = localStorage.getItem('user_token');

      (async () => {


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/profiles?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                profiles: data,
            });
        })
        .catch(err => {

        });

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-cities?access_token=${token}&has-groups=1`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                city_options: data.map((a) => {
                  return { value: a._id, label: a.city_name };
                })
            });
        })
        .catch(err => {

        });


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/group-libraries?all=true&access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            libraries: data,
          });
        })
        .catch(err => {

        });

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/group-libraries?public=true&access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            public_libraries: data,
          });
        })
        .catch(err => {

        });



      })()

    }

    toggleAddGroup(group_id) {      
      if (this.props.selected.indexOf(group_id) > -1) {
        this.props.selected.splice(this.props.selected.indexOf(group_id), 1);
      } else {
        this.props.selected.push(group_id);
      }
      this.props.onChange(this.props.selected);
    }

    addAllGroup(group_ids) {
      // window.alert(group_ids)
      for (var i = 0; i < group_ids.length; i++) {
        // if (this.props.selected.indexOf(group_ids[i]) === -1) {
        //   this.props.selected.push(group_ids[i]);
        // }

      if (this.props.selected.indexOf(group_ids[i]) > -1) {
        this.props.selected.splice(this.props.selected.indexOf(group_ids[i]), 1);
      } else {
        this.props.selected.push(group_ids[i]);
      }



      }
      // this.props.selected = [...new Set(this.props.selected.concat(group_ids))]
      this.props.onChange(this.props.selected);
    }

    selectAll() {
      this.props.onChange([...new Set(this.props.selected.concat(this.state.groups.map((a) => a._id)))]);
    }
    unselectAll() {
      this.props.onChange(this.props.selected.filter((g) => {
        return this.state.groups.map((a) => a._id).indexOf(g) === -1;
      }));
    }

    render() {
      const { t, i18n } = this.props;

        return (
          <div>
            <div className="sss">
                <div className="row filter-section-parant">
                  <div className="col-md-4 sort-by-option">
                    <label>{this.props.t('Sort By')}</label>
                    <select 
                      value={this.state.sort_by}
                      onChange={(e) => { this.setState({sort_by: e.target.value}); this.get_groups() }}
                      className="form-control"
                      >
                      <option value={null}>{this.props.t('Sort By')}</option>

                      {/*<option value={'today_slot_count'}>{this.props.t('Slot Count')}</option>*/}
                      <option value={'users_in_group_count'}>{this.props.t('User in group')}</option>
                      <option value={'follow_count'}>{this.props.t('Followers')}</option>
                      {/*<option value={'today_slot_count'}>{this.props.t('Pending Profile Amount')}</option>*/}
                      {/*<option value={'blocked_users_in_group_count'}>{this.props.t('Blocked Profile Amount')}</option>*/}
                    </select>
                  </div>
                  <div className="col-md-4 filter-by-option">
                    <label>{this.props.t('Group Status')}</label>
                    <select 
                      value={this.state.filter_query.group_status}
                      onChange={(e) => {
                        this.state.filter_query.group_status = e.target.value;
                        this.setState({filter_query: this.state.filter_query});
                        this.get_groups()
                      }}
                      className="form-control"
                      >
                      
                      <option value={null}>{this.props.t('All')}</option>
                      <option value={'automatic_approval'}>{this.props.t('Automatic Approval')}</option>
                      <option value={'not_automatic_approval'}>{this.props.t('Not Automatic Approval')}</option>
                      <option value={'closed_groups'}>{this.props.t('Closed Groups')}</option>
                    </select>
                  </div>
                  {/*<div className="col-md-3 filter-by-profile">
                    <label>{this.props.t('Profile')}</label>
                    <select 
                      value={this.state.filter_by_profile}
                      onChange={(e) => { this.setState({filter_by_profile: e.target.value}); this.get_groups() }} 
                      className="form-control" 
                      >
                        <option value="">{this.props.t('Select a Profile')}</option>
                        {this.state.profiles.map((profile) => <option value={profile._id}>{profile.first_name + ' ' + profile.last_name}</option> )}
                    </select>
                  </div>*/}
                  <div className="col-md-4 filter-by-profile dddd">
                    <label>{this.props.t('Library')}</label>
                    <Select 
                      isSearchable={true}
                      value={this.state.filter_by_library}
                      onChange={(value) => { this.setState({filter_by_library: value.value}); this.get_groups() }} 
                      styles={selectStyles}
                      options={[
    {
      label: this.props.t('Custom Libraries'),
      options: this.state.libraries.map((lib) => {
                        return {
                          label: `${lib.name} (${lib.facebook_groups.length})`,
                          value: lib._id
                        }
                      })
    },
    {
      label: this.props.t('Public Libraries'),
      options: this.state.public_libraries.map((lib) => {
                        return {
                          label: `${lib.name} (${lib.facebook_groups.length})`,
                          value: lib._id
                        }
                      })
    }
  ]}
                      >
                        <option value={null}>{this.props.t('Select a Library')}</option>
                        <optgroup label={this.props.t('Custom Libraries')}>
                          {this.state.libraries.filter((a) => a._id !== this.props.match.params.library_id).map((lib) => <option value={lib._id}>{lib.name} ({lib.facebook_groups.length})</option> )}
                        </optgroup>
                        <optgroup label={this.props.t('Public Libraries')}>
                          {this.state.public_libraries.filter((a) => a._id !== this.props.match.params.library_id).map((lib) => <option value={lib._id}>{lib.name} ({lib.facebook_groups.length})</option> )}
                        </optgroup>
                    </Select>
                  </div>
                </div>

              </div>
              <div className="row">

                    <div className="search-section" style={{marginTop: '20px'}}>
                    
                      <div className="input-group" style={{paddingBottom: '10px'}}>
        <TagsInput
          value={this.state.search_text}
          onChange={(value) => {
              this.setState({search_text: value});
              this.get_groups(this.props);
          }}
          name="fruits"
          placeholder={this.props.t('Search Groups')}
          type="text"
          style={{
            position: 'relative',
            flex: '1 1 auto',
            width: '1%',
            minWidth: '0',
            marginBottom: '0',
          }}
        />

                        {/*<input className="form-control" placeholder={this.props.t('Search Groups')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value}); this.get_groups(this.props);}}/>*/}
                        {!this.props.groupSettings && <button className="btn btn-success" style={{
                          borderRadius: '12px',
                          lineHeight: '33px',
                        }} onClick={this.selectAll.bind(this)}>{this.props.t('Select All')} ({this.state.groups.filter((a) => this.props.selected.indexOf(a._id) == -1).length})</button>}
                        {!this.props.groupSettings && <button className="btn btn-danger" style={{
                          borderRadius: '12px',
                          lineHeight: '33px',
                        }} onClick={this.unselectAll.bind(this)}>{this.props.t('Remove All')} ({this.state.groups.filter((a) => this.props.selected.indexOf(a._id) != -1).length})</button>}
                      </div>

                {this.state.city_options.length > 0 && <div className="form-group dddd">
                  <label htmlFor="exampleInputEmail1">{this.props.t('Cities')} {this.state.search_city_filter_options && this.state.search_city_filter_options.length && (<button onClick={(e) => {
                      this.setState({search_cities: this.state.search_city_filter_options.map((a) => a.value)});
                      this.get_groups(this.props);
                  }}>{this.state.search_city_search_text} {this.state.search_city_filter_options.length} - Select All</button>)}</label>
                  <Select value={this.state.city_options.filter((a) => {
                    return this.state.search_cities.indexOf(a.value) > -1;
                  })} onSelectResetsInput={false} onBlurResetsInput={false} closeMenuOnSelect={false} options={this.state.city_options} isMulti onChange={(e) => {
                          this.setState({search_cities: e.map((a) => a.value)});
                          this.get_groups(this.props);
                        }} onInputChange={(search_text,b,c) => {
                          // console.log(a,b,c, 'ddddddddd')
                          var search_city_filter_options = this.state.city_options.filter((a) => {
                            return a.label.toLowerCase().indexOf(search_text.toLowerCase()) > -1
                          });

                          setTimeout(() => {
                            this.setState({
                              search_city_filter_options: search_city_filter_options,
                              search_city_search_text: search_text,
                            });
                          }, 100);

                        }}/>
                </div>}

                    </div>
                    <div className="search-section" style={{marginTop: '20px'}}>
                    
                    {/*<div className="row">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">{this.props.t('Follower Count Range')}</label>
                        <PrettoSlider min={2} max={30} step="1" valueLabelDisplay="auto" aria-label="pretto slider" value={this.state.max_comments} onChange={(e, value) => {this.setState({ max_comments: value })}}/>
                      </div>
                    </div>*/}


                    </div>
                    <div className="col-md-12s">

                    <div className="group-info group-info-banner">
                      <strong>{this.props.t(`Showing`)}:</strong> {this.state.groups.length} {this.props.t(`groups`)} {this.props.t('of')} {this.state.total_group_count || 0}
   
                      {!this.props.groupSettings && <span className="by-selected-section">
                        {this.props.t('What groups to display')}? &nbsp;
                        <label className="options" label="Female">
                          {this.props.t('All')}
                          <Radio 
                            onChange={(e) => {
                              this.state.filter_query.by_selected.value = e.target.value;
                              this.setState({filter_query: this.state.filter_query});
                              this.get_groups()
                            }}

                            value="all" 
                            name="by_selected" 
                            checked={this.state.filter_query.by_selected.value === 'all'
                          }/>
                        </label>
                        <label className="options" label="Female">
                          {this.props.t('Selected')}
                          <Radio 
                            onChange={(e) => {
                              this.state.filter_query.by_selected.value = e.target.value;
                              this.setState({filter_query: this.state.filter_query});
                              this.get_groups()
                            }}

                            value="selected" 
                            name="by_selected" 
                            checked={this.state.filter_query.by_selected.value === 'selected'
                          }/>
                        </label>
                        <label className="options" label="Female">
                          {this.props.t('Unselected')}
                          <Radio 
                            onChange={(e) => {
                              this.state.filter_query.by_selected.value = e.target.value;
                              this.setState({filter_query: this.state.filter_query});
                              this.get_groups()
                            }}

                            value="unselected" 
                            name="by_selected" 
                            checked={this.state.filter_query.by_selected.value === 'unselected'
                          }/>
                        </label>
                      </span>}

                      </div>
                    </div>
                    
                <div className="manage-group-container-parent">
                    <div className="manage-group-container">

                {this.state.loading ? <div className="loader">
                    <svg width="300px" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                      <circle fill="none" stroke="#fff" strokeWidth={4} cx={50} cy={50} r={44} style={{opacity: '0.5'}} />
                      <circle fill="#fff" stroke="#e74c3c" strokeWidth={3} cx={8} cy={54} r={6}>
                        <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 48" to="360 50 52" repeatCount="indefinite" />
                      </circle>
                    </svg>
                   </div> : this.state.groups.chunk_inefficient(8).map((groups, index) => (<div style={{overflow: 'hidden', position: 'relative'}}>

                   <button style={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      margin: '0 auto',
                      width: '94px',
                      top: '243px',
                   }}
                   type={'button'}
                   onClick={this.addAllGroup.bind(this, groups.map((a) => a._id))}
                   >{this.props.t('Select 8 Groups')}</button> {groups.map((group, index) => (<React.Fragment>

                   

                   <div style={{padding: '13px', width: '25%', float: 'left'}}>
                
                        <span dir="ltr" className={"text-decoration-none my-1 d-block square group " + (this.props.selected.indexOf(group._id) > -1 ? ' active': '') }>
                          
                          <div className="rounded rounded-circle ">
                            <img style={{left: 0}} className="group-image img img-thumbnail img-fluid rounded rounded-circle " src={group.image_url} g="img/group1.png" alt="" />
                            
                            <span className="position-absolute top-50 start-50 translate-middle bg-dark-75 rounded rounded-circle w-75 h-75">
                              {group.auto_approve && <span className="automatic-approval-badge badge bg-info px-2 py-2 small text-white rounded-pill position-75">{this.props.t('Automatic Approval')}</span>}
                              <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder"><a target="_blank" href={`https://facebook.com/groups/${group.group_id}`} style={{color: '#fff'}}>{group.name}</a></p>
                            </span>

                          </div>
                          <span className="badge bg-info px-2 py-2 small text-white rounded-pill position-25"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/follower.e4d49cda.svg" g="icons/follower.svg" />{nFormatter(group.follow_count, 2)}</span>
                          <span className="badge bg-info px-2 py-2 small text-white rounded-pill position-75"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/clock.66a84231.svg" g="icons/clock.svg" />{group.today_slot_count}<img className="d-inline mx-1 h-icon" width="5px" src="/static/media/account.9ec423dc.svg" g="icons/account.svg" />{group.users_in_group_count}</span>
                          
                          {<span style={{left: '36px'}} className="badge bg-info text-white rounded-pill position-absolute bottom-0">
                            {this.props.t('Pending profiles')}: {group.pending_users_in_group_count} <br/>
                            {this.props.t('Blocked profiles')}: {group.blocked_users_in_group_count}
                            {/*a: {group.auto_approval_percentage}<br/>
                            b: {group.approval_percentage}*/}
                          </span>}
                        </span>

                        {!this.props.groupSettings && <button onClick={this.toggleAddGroup.bind(this, group._id)} className={`btn ${(this.props.selected.indexOf(group._id) > -1 ? 'btn-success': 'btn-success')}`}>
                          {/*this.state.base_groups.map((a) => a._id).indexOf(group._id)*/}
                          {(this.props.selected.indexOf(group._id) > -1 ? this.props.t('Unselect'): this.props.t('Select'))}
                          {(this.props.selected.indexOf(group._id) > -1 ? <FaCheck/> : null)}
                        </button>}
                        {this.props.groupSettings && <button onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console', 'groups', group._id]})} className={`btn ${(this.props.selected.indexOf(group._id) > -1 ? 'btn-danger': 'btn-success')}`}>
                          {this.props.t('Modify Settings')}
                        </button>}
                        {<button onClick={this.scanGroup.bind(this, group._id)} className={`btn btn-warning`}>{this.props.t('Scan')}</button>}
                      </div></React.Fragment>)) }</div>)  )}

                    </div>
                    </div>

              </div>
          </div>
        );
    }
}

GroupSearch.propTypes = {};

GroupSearch.defaultProps = {};

export default withRouter(GroupSearch);
