import React from 'react';
import PropTypes from 'prop-types';
import styles from './ScheduleCampaignPopup.module.scss';
import fetch from 'node-fetch';
import _ from 'lodash';

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import moment from 'moment';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import {
  BrowserRouter as Router,
  Switch as SwitchRouter,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';


import DateFnsUtils from '@date-io/date-fns';
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";



import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';


function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);





class ScheduleCampaignPopup extends React.Component {

    state = {
    	post: null,
  	  selected: null,
      profiles: [],
      campaigns: null,
      posts: [],
      selected_groups: [],
      selected_posts: [],
      groups: null,
      edit_campaign_posts: [],
      selected_edit_campaign: null,
      selected_campaign: null,
      times: [
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ]


    }

    refresh() {

    }

    save() {


        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/save-schedule-campaign?access_token=${token}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                post: this.state.post,
                is_multi: this.state.is_multi,
                multi_select: this.state.multi_select,
                allow_edit: this.state.allow_edit,
              }),
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('saved scheduled campaign successfully');
          this.props.updateSearch({'edit-scheduled-camaign': undefined, "new-post": Math.random()})
        })
        .catch(err => {
            NotificationManager.error('Error adding campaign');
        })




    }
    get_posts(state_name, campaign_id) {
        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/posts?access_token=${token}&campaign=${campaign_id}`, {})
        .then(response => response.json())
        .then(data => {
          var q = {};
          q[state_name] = data;
          this.setState(q);
        })
        .catch(err => {

        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.post && this.state.post.settings.edit_campaign && this.state.post.settings.edit_campaign !== this.state.selected_edit_campaign) {
            this.setState({
                selected_edit_campaign: this.state.post.settings.edit_campaign,
            })
            setTimeout(() => {
                this.get_posts('edit_campaign_posts', this.state.post.settings.edit_campaign);
            }, 1);
        }

        if (this.state.post && this.state.post.campaign._id && this.state.post.campaign._id !== this.state.selected_campaign) {
            this.setState({
                selected_campaign: this.state.post.campaign._id,
            })
            setTimeout(() => {
                this.get_posts('posts', this.state.post.campaign._id);
            }, 1);
        }
    }

    update_data(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-campaign-slot?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
          data.settings = data.settings || {};
        	this.setState({
        		post: data,
        	});
        })
        .catch(err => {

        });


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/profiles?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                profiles: data,
            });
        })
        .catch(err => {

        });;

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/campaigns?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                campaigns: data,
            });
        })
        .catch(err => {

        });


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/groups?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            groups: data,
          });
            this._sort();
        })
        .catch(err => {

        });


    }

    componentWillReceiveProps(props) {

        var id = this.props.location.pathname.split('/').pop()

    	
    	if (id !== this.state.selected) {
			setTimeout(() => {
				this.update_data(props);	
			}, 0)
    	}
    }

    componentWillMount(props) {
        var query = queryString.parse(this.props.location.search);

    	this.setState({
    		selected: query['edit-scheduled-camaign'].split(',')[0],
        is_multi: query['edit-scheduled-camaign'].indexOf(',') > -1,
        multi_select: query['edit-scheduled-camaign'].split(','),
        allow_edit: query['edit-scheduled-camaign'].indexOf(',') === -1 ? {
          profiles: true,
          max_post_amount: true,
          post_per_period: true,
          skip_period: true,
          should_delete: true,
          should_pump: true,
          should_edit: true,
        } : {
          profiles: false,
          max_post_amount: false,
          post_per_period: false,
          skip_period: false,
          should_delete: false,
          should_pump: false,
          should_edit: false,
        },
    	});
		
  		setTimeout(() => {
  			this.update_data(this.props);	
  		}, 0)
    }

    check_if_pending(date) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/check-pending?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
        	NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {
        });
    }

    fromNow(date) {
    	// return moment(date).relativeTimeThreshold('m', 60);
    	return (moment(date)).fromNow();
    }

    togglUser(user_id) {
       this.state.post.users = _.xor(this.state.post.users, [user_id]);
       this.setState({
           users: this.state.post
       });
    }

    toggleAddGroup(group_id) {      
      if (this.state.selected_groups.indexOf(group_id) > -1) {
        this.state.selected_groups.splice(this.state.selected_groups.indexOf(group_id), 1);
      } else {
        this.state.selected_groups.push(group_id);
      }

      this.setState({
        selected_groups: this.state.selected_groups,
      });
    }
    toggleAddPost(group_id) {      
      if (this.state.selected_posts.indexOf(group_id) > -1) {
        this.state.selected_posts.splice(this.state.selected_posts.indexOf(group_id), 1);
      } else {
        this.state.selected_posts.push(group_id);
      }

      this.setState({
        selected_posts: this.state.selected_posts,
      });
    }
    getDisplayGroups(sort_by, search_text, amount_to_display, filter_by_profile, filter_query, selected) {
      search_text = search_text || '';
      this.state.groups.map((g) => {
        g.follow_count = g.follow_count | 0;
      })

      var groups = _.orderBy(this.state.groups, [sort_by, 'follow_count', 'today_slot_count', 'users_in_group_count'],['desc'])

      if (filter_query && filter_query.by_selected.value !== 'all') {

        groups = groups.filter((group) => {
          if (filter_query.by_selected.value === 'unselected') {
            return selected.indexOf(group._id) === -1;
          } else {
            return selected.indexOf(group._id) > -1;
          }
        })

        // 

      }

      if (filter_by_profile) {
        groups = groups.filter((group) => {
          return Object.keys(this.state.profile_group[filter_by_profile] || {}).indexOf(group._id) > -1
        })
      }

      return groups.filter((group) => {
          return group.name.toLowerCase().indexOf(search_text.toLowerCase()) > -1;
        })
        .slice(0,amount_to_display)

    }

    render() {
    	const { t, i18n } = this.props;

        return (
		  <div className="overlay style-light">
              {this.state.post && this.state.campaigns && this.state.groups ? <div className="bg-primary p-5 text-white text-center rounded-3 " id="myForm">
                  
                  <div className="pb-5 position-relative" id="">
                    <h4 className="fs-5 fw-bolder text-uppercase text-center">{this.props.edit ? t('Schedule Campaign') : t('Schedule Campaign')}</h4>
                    <a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {'edit-scheduled-camaign': undefined})}>×</a>
                  </div>

                  <div className="pb-5 position-relative" id="signin_header" style={{minWidth: '700px'}}>
                    <div>
                      <h4>{t('Select Campaign')}</h4>
                      <select className="form-control" value={this.state.post.campaign._id} onChange={(e) => { this.state.post.campaign._id = e.target.value; this.setState({post: this.state.post}) }}>
                        {this.state.campaigns.map((campaign)=>(<option value={campaign._id}>{campaign.name}</option>))}
                      </select>
                    </div>

                    <div>
                      <h4>{t('What posts?')}</h4>

                      <div>
                          {t('All posts')}
                          <Switch
                            classes={this.props.classes}
                            checked={this.state.post.settings.all_posts}
                            onClick={(e) => { this.state.post.settings.all_posts = !this.state.post.settings.all_posts; this.setState({
                              post: this.state.post,
                            }) }}
                          />
                      </div>

                      {!this.state.post.settings.all_posts && <div>
                      <span>({this.state.selected_groups.length} {t('groups selected')})</span>

                        {/*<input className="form-control" placeholder={t('Search Groups')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>*/}

                        <div style={{overflowY: 'scroll', height: '500px', border: '1px solid black'}}>
                          {this.state.posts.map((post) => (<div style={{padding: '13px', width: '33%', float: 'left'}}>
                            <span dir="ltr" className={"text-decoration-none my-1 d-block square post " + (this.state.selected.indexOf(post._id) > -1 ? ' active': '') }>
                              <div className="rounded rounded-circle ">
                                {post.media_url && <img style={{left: 0}} className="post-image text-center img img-thumbnail img-fluid " src={post.media_url.split(',')[0]} g="img/post.png" alt="" />}
                                {(post.type === 'text-background' || post.type === 'text') && <span style={{backgroundColor: '#d37554'}} className="position-absolute top-50 start-50 translate-middle bg-dark-75 w-75 h-75">
                                  <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder">
                                    {post.post_content}
                                  </p>
                                </span>}
                              </div>
                            </span>
                            <button onClick={this.toggleAddPost.bind(this, post._id)} className={`btn ${(this.state.selected_posts.indexOf(post._id) > -1 ? 'btn-danger': 'btn-success')}`}>{(this.state.selected_posts.indexOf(post._id) > -1 ? t('Unselect'): t('Select'))}</button>
                          </div>))}
                        </div>
                      </div>}
                      
                    </div>

                    <div>
                      <h4>{t('At what times?')}</h4>

                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <button className="btn btn-success">+</button>
                        </div>
                        <div className="input-group-prepend">
                          <button className="btn btn-danger">-</button>
                        </div>

                        <select className="form-control" value={this.state.post.time_slot} onChange={(e) => { this.state.post.time_slot = e.target.value; this.setState({post: this.state.post}) }}>
                          {this.state.times.map((time)=>(<option value={time}>{time}:00</option>))}
                        </select>

                        {/*<div className="input-group-append">
                          <span className="input-group-text">.00</span>
                        </div>*/}
                      </div>
                    </div>

                    <div>
                      <h4>{t('Where?')}</h4>

                      <span>({this.state.selected_groups.length} {t('groups selected')})</span>

                      <input className="form-control" placeholder={t('Search Groups')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>

                      <div style={{overflowY: 'scroll', height: '500px', border: '1px solid black'}}>
                        {this.getDisplayGroups('follow_count', this.state.search_text).map((group) => (<div style={{padding: '13px', width: '20%', float: 'left'}}>
                          <span dir="ltr" className={"text-decoration-none my-1 d-block square group " + (this.state.selected.indexOf(group._id) > -1 ? ' active': '') }>
                            <div className="rounded rounded-circle ">
                              <img style={{left: 0}} className="group-image img img-thumbnail img-fluid rounded rounded-circle " src={group.image_url} g="img/group1.png" alt="" />
                              <span className="position-absolute top-50 start-50 translate-middle bg-dark-75 rounded rounded-circle w-75 h-75">
                                <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder"><a target="_blank" href={`https://facebook.com/groups/${group.group_id}`}>{group.name}</a></p>
                              </span>
                            </div>
                            <span className="badge bg-info px-2 py-2 small text-white rounded-pill position-25"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/follower.e4d49cda.svg" g="icons/follower.svg" />{nFormatter(group.follow_count, 2)}</span><span className="badge bg-info px-2 py-2 small text-white rounded-pill position-75"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/clock.66a84231.svg" g="icons/clock.svg" />{group.today_slot_count}<img className="d-inline mx-1 h-icon" width="5px" src="/static/media/account.9ec423dc.svg" g="icons/account.svg" />{group.users_in_group_count}</span>
                          </span>
                            <button onClick={this.toggleAddGroup.bind(this, group._id)} className={`btn ${(this.state.selected_groups.indexOf(group._id) > -1 ? 'btn-danger': 'btn-success')}`}>{(this.state.selected_groups.indexOf(group._id) > -1 ? t('Unselect'): t('Select'))}</button>
                        </div>))}
                      </div>


                    </div>

                    <div>
                      <h4>{t('From what profiles?')}</h4>
                      {this.state.allow_edit.profiles && <div>
                            <button className="btn btn-warning" onClick={() => {
                               this.state.post.users = this.state.profiles.map((profile) => profile._id);
                               this.setState({
                                   users: this.state.post
                               });
                            }}>{t("Mark All")}</button>
                      {this.state.profiles.map((profile) => (<React.Fragment><span className="position-relative"><span data-tip={profile.first_name + " " + profile.last_name} onClick={this.togglUser.bind(this, profile._id)} className={"me-3 " + ((this.state.post.users.indexOf(profile._id) > -1) ? 'active' : '')} key={profile._id}>
                        <img width="60px" className={`profile rounded rounded-circle border border-3  ${profile.is_logged_in ? 'logged_in' : 'logged_out'} `} src={'/api/users/profile/' + profile._id} g="img/user1.png" alt="" />
                      </span></span></React.Fragment>))}
                      </div>}
                    </div>

                    <div>
                      <h4>{t('How many posts?')}</h4>
                      
                    </div>

      {this.state.is_multi && <h2>Mass Update ({this.state.multi_select.length})</h2>}
      {this.state.is_multi && <div>
        <div>
            {t('Allow Edit Profiles')}
            <Switch
              classes={this.props.classes}
              checked={this.state.allow_edit.profiles}
              onClick={(e) => { this.state.allow_edit.profiles = !this.state.allow_edit.profiles; this.setState({
                allow_edit: this.state.allow_edit,
              }) }}
            />
        </div>
        <div>
            {t('Allow Edit max_post_amount')}
            <Switch
              classes={this.props.classes}
              checked={this.state.allow_edit.max_post_amount}
              onClick={(e) => { this.state.allow_edit.max_post_amount = !this.state.allow_edit.max_post_amount; this.setState({
                allow_edit: this.state.allow_edit,
              }) }}
            />
        </div>
        <div>
            {t('Allow Edit post_per_period')}
            <Switch
              classes={this.props.classes}
              checked={this.state.allow_edit.post_per_period}
              onClick={(e) => { this.state.allow_edit.post_per_period = !this.state.allow_edit.post_per_period; this.setState({
                allow_edit: this.state.allow_edit,
              }) }}
            />
        </div>
        <div>
            {t('Allow Edit skip_period')}
            <Switch
              classes={this.props.classes}
              checked={this.state.allow_edit.skip_period}
              onClick={(e) => { this.state.allow_edit.skip_period = !this.state.allow_edit.skip_period; this.setState({
                allow_edit: this.state.allow_edit,
              }) }}
            />
        </div>
        <div>
            {t('Allow Edit should_delete')}
            <Switch
              classes={this.props.classes}
              checked={this.state.allow_edit.should_delete}
              onClick={(e) => { this.state.allow_edit.should_delete = !this.state.allow_edit.should_delete; this.setState({
                allow_edit: this.state.allow_edit,
              }) }}
            />
        </div>
        <div>
            {t('Allow Edit should_pump')}
            <Switch
              classes={this.props.classes}
              checked={this.state.allow_edit.should_pump}
              onClick={(e) => { this.state.allow_edit.should_pump = !this.state.allow_edit.should_pump; this.setState({
                allow_edit: this.state.allow_edit,
              }) }}
            />
        </div>
        <div>
            {t('Allow Edit should_edit')}
            <Switch
              classes={this.props.classes}
              checked={this.state.allow_edit.should_edit}
              onClick={(e) => { this.state.allow_edit.should_edit = !this.state.allow_edit.should_edit; this.setState({
                allow_edit: this.state.allow_edit,
              }) }}
            />
        </div>
      </div>}





					<SwitchRouter>
						<Route path='/:lang/console/schedule/automatic/:interval/:period?'>
						</Route>
						<Route path='/'>
							<a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console']})}>×</a>
						</Route>
					</SwitchRouter>

                  </div>

					{this.state.post.submitted_post && this.state.post.submitted_post._errors && this.state.post.submitted_post._errors.map((error) => ( <div className="alert alert-danger" role="alert">
						{error}
					</div>))}





{this.state.allow_edit.max_post_amount && <div>
  <h4>{t('Max Post Amount')}</h4>
  <PrettoSlider step="1" min={1} max={100} valueLabelDisplay="auto" aria-label="pretto slider" value={this.state.post.max_post_amount} onChange={(e, value) => {this.state.post.max_post_amount = value; this.setState({ post: this.state.post })}}/>
</div>}
{this.state.allow_edit.post_per_period && <div>
  <h4>{t('Post Per Period')}</h4>
  <PrettoSlider step="1" min={1} max={50} valueLabelDisplay="auto" aria-label="pretto slider" value={this.state.post.post_per_period} onChange={(e, value) => {this.state.post.post_per_period = value; this.setState({ post: this.state.post })}}/>
</div>}
{this.state.allow_edit.skip_period && <div>
  <h4>{t('Skip Period')}</h4>
  <PrettoSlider step="1" min={0} max={5} valueLabelDisplay="auto" aria-label="pretto slider" value={this.state.post.skip_period} onChange={(e, value) => {this.state.post.skip_period = value; this.setState({ post: this.state.post })}}/>
</div>}
      

      {/*<div className=" border shadow my-4 overflow-auto mh-75">
        <div className="m_row">
          <div className="m_col p-4 text-muted"><strong>2:00</strong></div>
          <div className="m_col border-bottom border-start">
            <div style={{minHeight: '100px', width: '100%', color: 'white', fontSize: '1rem', lineHeight: 'normal', float: 'left', backgroundColor: 'rgb(250, 250, 250)'}} />
          </div>
        </div>
      </div>*/}

					<hr/>


{<div className="settings">
    <MuiPickersUtilsProvider utils={DateFnsUtils}>

      {this.state.allow_edit.should_delete && <div>
        <div>
            {t('Should Delete')}
            <Switch
              classes={this.props.classes}
              checked={this.state.post.settings.should_delete}
              onClick={(e) => { this.state.post.settings.should_delete = !this.state.post.settings.should_delete; this.setState({
                post: this.state.post,
              }) }}
            />
        </div>

        {this.state.post.settings.should_delete && <TimePicker
          label={t("Delete At")}
          inputVariant="outlined"
          value={this.state.post.settings.delete_at}
          onChange={ (newValue)=> {
              this.state.post.settings.delete_at = newValue;
              this.setState({
                  post: this.state.post,
              })
          }}
        />}
      </div>}

      {this.state.allow_edit.should_pump && <div>
        <div>
            {t('Should Pump')}
            <Switch
              classes={this.props.classes}
              checked={this.state.post.settings.should_pump}
              onClick={(e) => { this.state.post.settings.should_pump = !this.state.post.settings.should_pump; this.setState({
                post: this.state.post,
              }) }}
            />
        </div>
        {this.state.post.settings.should_pump && <div>
            {t('Pump Every Minutes')}
            <input 
              type="number"
              name="pump_every_minutes"
              value={this.state.post.settings.pump_every_minutes}
              min="0"
              onChange={(e) => { 
                  this.state.post.settings.pump_every_minutes = e.target.value; 
                  this.setState({
                    post: this.state.post,
                  })
              }}
            />
        </div>}
      </div>}

      {this.state.allow_edit.should_edit && <div>
        <div>
            {t('Should Edit')}
            <Switch
              classes={this.props.classes}
              checked={this.state.post.settings.should_edit}
              onClick={(e) => { this.state.post.settings.should_edit = !this.state.post.settings.should_edit; this.setState({
                post: this.state.post,
              }) }}
            />
        </div>
        {this.state.post.settings.should_edit && <div>
            {t('Edit After Minutes')}
            <input 
              type="number"
              name="edit_after_minutes"
              value={this.state.post.settings.edit_after_minutes}
              min="0"
              onChange={(e) => { 
                  this.state.post.settings.edit_after_minutes = e.target.value; 
                  this.setState({
                    post: this.state.post,
                  })
              }}
            />
        </div>}
      </div>}

      {this.state.post.settings.should_edit && <div>
          {t('Campaign')}
          <select value={this.state.post.settings.edit_campaign} onChange={(e) => {
                this.state.post.settings.edit_campaign = e.target.value; 
                this.setState({
                  post: this.state.post,
                })
          }}>
              <option></option>
              {this.state.campaigns.map((campaign) => <option value={campaign._id}>{campaign.name}</option>)}
          </select>

              <div className="select-edit-posts">
                {this.state.edit_campaign_posts.map((post) => (
                    <React.Fragment>

                      <span className={`position-relative select-edit-post ${post._id == this.state.post.settings.edit_post ? 'selected' : ''}`} onClick={() => {
                            this.state.post.settings.edit_post = post._id; 
                            this.setState({
                              post: this.state.post,
                            })
                      }}>
                        <span className="text-background d-block m-1 border border-2 border-secondary text-center position-relative post" >

                                {(!post.type || post.type === 'image') && 
                                  <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                                }

                                {(post.type === 'video') && 
                                  <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                                }

                                {(post.type === 'album') && 
                                  <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                                }

                                {(post.type === 'text-background' || post.type === 'text') && 
                                  <span className="text-background">
                                     {post.post_content}
                                  </span>
                                }
                        </span>



                    </span>

                    </React.Fragment>
                ))}
               </div>

      </div>}



    </MuiPickersUtilsProvider>


  <br/>
  <br/>
</div>}


          <hr/>
                        <button onClick={this.save.bind(this)} type="submit" className="btn w-75 fw-bold bg-white py-3 px-5 mt-4 text-primary rounded rounded-pill">{t('Save')}</button>
                    <hr/>



         </div> : <div className="loader">
                  <svg width="300px" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                    <circle fill="none" stroke="#fff" strokeWidth={4} cx={50} cy={50} r={44} style={{opacity: '0.5'}} />
                    <circle fill="#fff" stroke="#e74c3c" strokeWidth={3} cx={8} cy={54} r={6}>
                      <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 48" to="360 50 52" repeatCount="indefinite" />
                    </circle>
                  </svg>
                 </div>}
		  </div>
        );
    }
}

ScheduleCampaignPopup.propTypes = {};

ScheduleCampaignPopup.defaultProps = {};

export default ScheduleCampaignPopup;



